import React from 'react'
import {
	Detail,
    Hr,
} from '@maggioli-design-system/react'

interface Props {
    showLine: boolean,
}

export default function Informativa({ showLine }: Props) {
  return (
    <div>
        <Detail>
            Maggioli Editore è un marchio di Maggioli S.p.A. Azienda con sistema di qualità certificato ISO 9001:2015.
            <br/><br/>
            47822 Santarcangelo di Romagna (RN) - Via del Carpino, 8
            Tel 0541/688111 - Fax 0541/622595
            <br/>
            <a href="https://www.maggiolieditore.it/" rel="noreferrer" target="_blank" className="color-brand-maggioli-04">www.maggiolieditore.it, </a>
            <a href= "mailto:clienti.editore@maggioli.it" className="color-brand-maggioli-04">clienti.editore@maggioli.it</a>
            <br/><br/>
            I diritti di traduzione, di memorizzazione elettronica, di riproduzione e di adattamento totale o 
            parziale, con qualsiasi mezzo, sono riservati per tutti i paesi.
            <br/><br/>
            Gli autori e l'editore declinano ogni responsabilità per eventuali errori e/o inesattezze relativi 
            alla elaborazione dei testi normativi e per l'eventuale modifica e/o variazione degli schemi e della modulistica.
            <br/><br/>
            Gli autori, pur garantendo la massima affidabilità delle opere, non rispondono di danni derivanti 
            dall'uso dei dati e delle notizie ivi contenuti. L'editore non risponde di eventuali danni causati da 
            involontari refusi o errori di stampa.
        </Detail>
        { showLine && <Hr spacing="xsmall"/> }
    </div>
  )
}
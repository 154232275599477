import { useEffect, useState } from "react";
import { setCookie } from "./Macros";

// scrolla in cima alla pagina
const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

// esegue il logout
const logout = () => {
    setCookie("loggedUser", "", -1);
    sessionStorage.clear();
    window.location.href = "/";
};

const useWindowSize = (): number => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<number>(undefined);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize(window.innerWidth);
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
};

const saveFile = (blob: Blob, filename: string) => {
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }, 100);
};

export { scrollTop, logout, useWindowSize, saveFile };

import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import {
    ActionsBar,
    ActionsBarItems,
    Banner,
    Button,
    Detail,
    Form,
    Grid,
    H3,
    H4,
    InputPassword,
    InputText,
    Row,
    Switch,
} from "@maggioli-design-system/react";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import { useNavigate } from "react-router-dom";
import { ISubmit } from "../interfaces/ISubmit";
import * as Functions from "../utils/Functions";
import * as UserService from "../services/UserServices";
import { getCookie } from "../utils/Macros";

interface Props {
    handleLoader: IHandleLoader;
}

export default function Account({ handleLoader }: Props): ReactElement {
    const history = useNavigate();

    const [name, setName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [oldPassword, setOldPassword] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [submit, setSubmit] = useState<ISubmit>({ text: "Salva", status: "" });
    const [showBanner, setShowbanner] = useState<boolean>(false);
    const [actionVisible, setActionVisible] = useState<boolean>(false);
    const [changePass, setChangePass] = useState<boolean>(false);
    const [notification, setNotification] = useState<boolean>(false);
    const [notificationMsg, setNotificationMsg] = useState<string>("");

    const loggedUser: string = getCookie("loggedUser");
    const loggedUserID: string = loggedUser !== null ? JSON.parse(loggedUser).id : "";

    useEffect(() => {
        if (loggedUserID === null) {
            setSubmit({ text: "Errore. Scollegarsi e rieffettuare il login.", status: "error" });
            return;
        }
        loadUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadUser = async () => {
        handleLoader.setLoading(true);
        const res = await UserService.getUser(loggedUserID);
        const data = await res.json();
        handleLoader.setLoading(false);
        if (data.status) {
            setName(data.data.name);
            setSurname(data.data.surname);
            setEmail(data.data.email);
            setNotification(data.data.active_notifications)
        }
    };

    const validation = () => {
        const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        if (password !== password2 && changePass) {
            setSubmit({ text: "Le password devono coincidere", status: "error" });
            window.setTimeout(() => setSubmit({ text: "Salva", status: "" }), 2000);
            return false;
        }

        if (oldPassword === "" && changePass) {
            setSubmit({ text: "Inserire la vecchia password", status: "error" });
            window.setTimeout(() => setSubmit({ text: "Salva", status: "" }), 2000);
            return false;
        }

        if (!pattern.test(password) && changePass) {
            setSubmit({ text: "Password non valida", status: "error" });
            setShowbanner(true);
            window.setTimeout(() => setSubmit({ text: "Salva", status: "" }), 2000);
            return false;
        }
        setShowbanner(false);

        if (name === "" || surname === "") {
            setSubmit({ text: "Nome e cognome sono obbligatori", status: "error" });
            window.setTimeout(() => setSubmit({ text: "Salva", status: "" }), 2000);
            return false;
        }

        return true;
    };

    const onClickSubmit = async (e) => {
        e.preventDefault();

        if (submit.status !== "" || !validation()) {
            return;
        }

        const payload = {
            "user_id": loggedUserID,
            name,
            surname,
            email,
            "old_password": changePass ? oldPassword : "",
            "password": changePass ? password : "",
            "enable": "1",
        };

        const res = await UserService.editUser(payload);
        const data = await res.json();
        if (!data.status) {
            setSubmit({ text: data.message, status: "error" });
            window.setTimeout(() => setSubmit({ text: "Modifica", status: "" }), 2000);
            return;
        }
        handleLoader.setLoading(true);
        setChangePass(false);
        window.setTimeout(() => history("/myBooks/page/1"), 1000);
    };

    const onClickDelete = async () => {
        const res = await UserService.deleteUser(loggedUserID);
        const data = await res.json();
        if (data.status) {
            Functions.logout();
        }
    };

    const actionBar = () => (
        <ActionsBar position="center" notVisible={!actionVisible}>
            <H4>
                Sei sicuro di voler eliminare l'account?
                <br />
                L'azione sarà irreversibile. Verrà inoltre eseguito il logout.
            </H4>
            <ActionsBarItems>
                <Button variant="primary-outline" icon="action-undo" onClick={() => setActionVisible(false)}>
                    Indietro
                </Button>
                <Button variant="error" icon="crud-delete" onClick={onClickDelete}>
                    Elimina
                </Button>
            </ActionsBarItems>
        </ActionsBar>
    );

    const changePassStyle = {
        height: changePass ? "100%" : "0px",
        overflow: "hidden",
        transition: "all 0.4s ease",
    };

    const onClickNotification = async () => {
        const newNotification: boolean = !notification;
        const res = await UserService.editNotifications(loggedUserID, newNotification ? 1 : 0);
        const data = await res.json();
        if (!data.status) {
            return;
        }
        setNotification(newNotification);
        setNotificationMsg(newNotification ? "Le notifiche sono state attivate!" : "Le notifiche sono state disattivate!");
        setTimeout(() => setNotificationMsg(""), 3000);
    }

    return (
        <div className="px-48 background-color-adjust-tone-19 pb-20">
            <H3 className="text-center pt-10 mb-5">Il tuo account</H3>
            <Grid columns="2">
                {/* <Grid columns="1"> */}
                <Grid className="">
                    {" "}
                    {/* DA RIMUOVERE IL MARGINE */}
                    <Form
                        onSubmit={(e: ChangeEvent<HTMLFormElement>) => onClickSubmit(e)}
                        rows="fit-vertically"
                        columns="1"
                        className="background-color-adjust-tone-18 padding-small border-radius-small"
                    >
                        <div className="text-center">
                            <label className="text-center padding-xxsmall border-radius-xsmall background-color-brand-maggioli-09 color-brand-maggioli-20">
                                {email}
                            </label>{" "}
                            <br />
                            <Detail>La mail è univoca e non può essere cambiata.</Detail>
                        </div>

                        {/* Input nome */}
                        <InputText label="Nome" value={name} onChange={(e: ChangeEvent<HTMLFormElement>) => setName(e.target.value)} />

                        {/* Input cognome */}
                        <InputText label="Cognome" value={surname} onChange={(e: ChangeEvent<HTMLFormElement>) => setSurname(e.target.value)} />

                        <Row>
                            <Button
                                variant="secondary-outline"
                                onClick={() => setChangePass(!changePass)}
                                icon={changePass ? "navigation-hide" : "navigation-show"}
                            >
                                Cambia password
                            </Button>
                        </Row>

                        <div style={changePassStyle}>
                            <Grid>
                                {/* Input vecchia password */}
                                <InputPassword
                                    label="Vecchia password"
                                    placeholder="Inserisci la vecchia password..."
                                    value={oldPassword}
                                    onChange={(e: ChangeEvent<HTMLFormElement>) => setOldPassword(e.target.value)}
                                />
                                {/* Input nuova password */}
                                <InputPassword
                                    label="Nuova password"
                                    placeholder="Inserisci una nuova password..."
                                    value={password}
                                    onChange={(e: ChangeEvent<HTMLFormElement>) => setPassword(e.target.value)}
                                />

                                {/* Input ripeti password */}
                                <InputPassword
                                    label="Ripeti password"
                                    placeholder="Ripeti la password..."
                                    value={password2}
                                    onChange={(e: ChangeEvent<HTMLFormElement>) => setPassword2(e.target.value)}
                                />

                                {showBanner && (
                                    <Banner status="error" className="border-radius-small">
                                        <Detail>
                                            La nuova password deve: <br />
                                            - essere lunga almeno 6 caratteri <br />
                                            - contenere almeno una lettera maiuscola <br />
                                            - contenere almeno una lettera minuscola <br />
                                            - contenere almeno un numero <br />
                                        </Detail>
                                    </Banner>
                                )}
                            </Grid>
                        </div>

                        {/* Bottone salvataggio modifiche account */}
                        <div>
                            <Button type="submit" onClick={onClickSubmit} icon="crud-edit">
                                Salva
                            </Button>
                        </div>
                        {submit.status !== "" && (
                            <Banner status={submit.status} className="border-radius-small">
                                <Detail>{submit.text}</Detail>
                            </Banner>
                        )}
                    </Form>
                    {/* Bottone eliminazione account */}
                    <Button icon="crud-delete" type="submit" variant="error-outline" onClick={() => setActionVisible(true)}>
                        Elimina account
                    </Button>
                </Grid>
                <Grid className="background-color-adjust-tone-18 padding-small border-radius-small" rows="fit-vertically">
                    <Row lastChild="to-right">
                        <H4>Notifiche</H4>
                        <Switch isChecked={notification} onChange={() => onClickNotification()} />
                    </Row>
                    <Detail>
                        Attiva/disattiva le notifiche per gli aggiornamenti dei volumi di cui hai riscattato i codici. <br />
                        Quando vengono aggiunti o modificati dei contenuti a cui puoi accedere, riceverai un'email informativa a riguardo. <br />
                    </Detail>
                    { notificationMsg !== "" &&
                     <Banner status={notification ? "success" : "info"} className="border-radius-small">
                        <Detail>{notificationMsg}</Detail>
                    </Banner>}
                </Grid>
            </Grid>

            {/* Action bar conferma eliminazione account */}
            {actionBar()}
        </div>
    );
}

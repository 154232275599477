import { ReactElement, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { H3, H5, Detail, Grid } from "@maggioli-design-system/react";
import { authorizeByToken } from "../services/UserServices";
import { getCookie, setCookie } from "../utils/Macros";
import { TailSpin } from "react-loader-spinner";
const ACCESS_TIME: number = 1500;

export default function Authorize(): ReactElement {
    const { token } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const isLoggedUser: boolean = getCookie("loggedUser") !== null;

    useEffect(() => {
        document.title = `Autorizza | Approfondimenti`;
        !isLoggedUser && attemptAuthorize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const attemptAuthorize = async () => {
        const res = await authorizeByToken(token);
        const data = await res.json();
        if (!data.status) {
            setLoading(false);
            return;
        }
        setCookie(
            "loggedUser",
            JSON.stringify({
                email: data.email,
                id: data.id,
            }),
        );
        redirectAfterLogin();
    };

    const redirectAfterLogin = () => {
        window.setTimeout(() => window.location.replace("/myBooks/page/1"), ACCESS_TIME);
    };

    if (!loading) {
        return (
            <Grid className="background-color-adjust-tone-19 my-auto" gutter="xxsmall">
                <H3 className="mx-auto mt-10">Attenzione</H3>
                <Detail className="mx-auto mb-10">Token non valido.</Detail>
            </Grid>
        );
    }

    if (isLoggedUser) {
        return (
            <Grid className="background-color-adjust-tone-19 my-auto" gutter="xxsmall">
                <H3 className="mx-auto mt-10">Attenzione</H3>
                <div className="mx-auto">L'accesso al portale risulta già effettuato.</div>
                <Link className="mx-auto mb-10" to="/">
                    <H5>Torna alla home</H5>
                </Link>
            </Grid>
        );
    }

    return (
        <Grid className="background-color-adjust-tone-19">
            <div className="mt-8 mx-auto">
                <TailSpin color="#0041B9" width={100} />
            </div>
            <Detail className="my-8 mx-auto">Autenticazione e accesso in corso...</Detail>
        </Grid>
    );
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { Caption, Code, Grid, H5, Image, Button } from "@maggioli-design-system/react";
import { IBook, IBookMeta } from "../interfaces/IBook";
import { uploads } from "../utils/Macros";

interface Props {
    book: IBook;
}

interface IBookCover {
    url: string;
}

const BookCover = ({ url }: IBookCover): JSX.Element => <Image className="rounded-lg shadow-lg" src={url} />;

export default function SideBook({ book }: Props) {
    const history = useNavigate();
    const bookImage: string =
        book !== null ? (book.guid === "" || book.guid === null ? `${process.env.PUBLIC_URL}/assets/images/no-book-cover.jpg` : uploads + book.guid) : "";
    const bookPages: IBookMeta = book !== null ? book.meta.filter((elem) => elem.meta_key.toLowerCase().includes("pag")).pop() : null;

    return (
        <div className="pb-10 border-b-2 border-adjust-tone-17 lg:pb-5 lg:border-0 lg:col-span-1">
            <Button className="mb-8" variant="primary-outline" icon="action-back" horizontalPadding="small" onClick={() => history(-1)}>
                Torna indietro
            </Button>
            <Grid>
                {/* Copertina del volume */}
                <BookCover url={bookImage} />

                {/* ISBN */}
                <Grid className="gap-0">
                    <H5>ISBN</H5>
                    <Code>{book.isbn}</Code>
                </Grid>

                {/* Numero di pagine (se c'è) */}
                {bookPages !== undefined && (
                    <Grid className="gap-0">
                        <H5>Pagine</H5>
                        <Caption>{bookPages.meta_value}</Caption>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

import React, { ReactElement } from "react";
import { UrlPreview } from "@maggioli-design-system/react";
import { IBook } from "../interfaces/IBook";
import Singleton from "../utils/Singleton";

interface Props {
    book: IBook;
    show: boolean;
    setShow: Function;
}

export default function ILibro({ book, show, setShow }: Props): ReactElement {
    // pezza ilibro
    const ilibroURL = () => {
        return `https://e-book.maggiolicloud.it/v2/x/${book.isbn.replaceAll(/\D/g, "")}?token=${Singleton.getInstance()}`;
    };

    return (
        show && (
            <UrlPreview
                wide
                centered={true}
                size="fit-window"
                title={`${book.title}`}
                className="z-20"
                url={ilibroURL()}
                onCancel={() => setShow(false)}
                visible={show}
            />
        )
    );
}

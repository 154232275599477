import { ChangeEvent, useState } from "react";
import { Button, Banner, Card, Detail, Grid, Icon, H4, InputText, UList, UListItem } from "@maggioli-design-system/react";
import { ISubmit } from "../interfaces/ISubmit";
import * as UserService from "../services/UserServices";
import { getCookie } from "../utils/Macros";
import { IUserRole } from "../interfaces/IUser";

interface ISidebar {
    className: string;
    user: IUserRole;
}

const INIT_TEXT: string = "";
const INIT_STAT: string = "";

const Sidebar = ({ className, user }: ISidebar): JSX.Element => {
    const loggedUser: string = getCookie("loggedUser");
    const loggedUserID: string = loggedUser !== null ? JSON.parse(loggedUser).id : "";

    const [code, setCode] = useState<string>("");
    const [submit, setSubmit] = useState<ISubmit>({ text: INIT_TEXT, status: INIT_STAT });

    const onClickRedeemCode = async () => {
        const res = await UserService.redeemCode(loggedUserID, code);
        const data = await res.json();
        if (!data.status) {
            setSubmit({ text: data.message, status: "error" });
            window.setTimeout(() => setSubmit({ text: INIT_TEXT, status: INIT_STAT }), 1500);
            return;
        }
        setSubmit({ text: "Codice aggiunto!", status: "success" });
        window.setTimeout(() => window.location.reload(), 700);
    };

    return (
        <Card rows="fit-vertically" className={`${className}`} boxShadow="none">
            {/* Riscatto codice */}
            {!user.isWorker && (
                <div className="background-color-adjust-tone-19 border-radius-normal padding-normal mb-4">
                    <H4 className="mb-2">Aggiungi un libro</H4>
                    <Grid columns="1" gutter="small">
                        <InputText
                            placeholder="Inserisci il codice..."
                            value={code}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => setCode(e.target.value)}
                        />
                        <Button onClick={() => onClickRedeemCode()} variant="info">
                            Aggiungi
                        </Button>
                        {submit.status !== "" && (
                            <Banner status={submit.status} className="border-radius-small">
                                <Detail>{submit.text}</Detail>
                            </Banner>
                        )}
                        <Detail>
                            All'interno delle pubblicazioni Maggioli Editore troverai un codice da attivare qui. Se il codice non è all'interno del
                            libro, il libro non contiene approfondimenti allegati.
                        </Detail>
                    </Grid>
                </div>
            )}

            {/* Descrizione iLibro */}
            <div>
                <img
                    alt="ilibro logo"
                    className="h-12 mb-2"
                    loading="lazy"
                    src={`${process.env.PUBLIC_URL}/assets/images/logo-ilibro-intelligente.svg`}
                />
                <Detail>
                    <b>iLibro</b> è la versione digitale del libro cartaceo arricchita con le seguenti funzionalità:
                </Detail>
                <UList iconSize="xsmall" autoPunctuation={false}>
                    <UListItem>
                        <Detail>
                            collegamento ipertestuale che consente l’immediata visualizzazione della normativa, prassi e giurisprudenza citata nel
                            libro
                        </Detail>
                    </UListItem>
                    <UListItem>
                        <Detail>
                            testi normativi sempre disponibili in versione aggiornata, con la possibilità di consultazione anche in modalità
                            “multivigenza”
                        </Detail>
                    </UListItem>
                    <UListItem>
                        <Detail>
                            motore di ricerca per parole chiave che consente l’immediata visualizzazione dei contenuti di interesse per il lettore
                        </Detail>
                    </UListItem>
                </UList>
            </div>

            {/* Note */}
            <div>
                <Icon name="warning" />
                <Detail>
                    <b>Attenzione</b>
                </Detail>
                <Detail>
                    Nella versione iLibro è possibile che non sia attivo il collegamento ipertestuale per alcuni provvedimenti di normativa, prassi e
                    giurisprudenza. La funzionalità può essere soggetta a revoca da parte dell’Editore.
                </Detail>
            </div>
        </Card>
    );
};

export { Sidebar };

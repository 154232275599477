import Connection from "../utils/Connection";
import * as Macros from "../utils/Macros";

export async function login(email: string, password: string, backoffice: boolean) {
    const payload = {
        "email": email,
        "password": password,
        "backoffice": backoffice,
    };

    return await Connection.post(Macros.postURL + "users/login", payload);
}

export async function passwordRecovery(email: string) {
    const payload = {
        "email": email,
    };

    return await Connection.put(Macros.putURL + "users/recoveryPassword", payload);
}

export async function resetPassword(password: string, code: string) {
    const payload = {
        "password": password,
        "code": code,
    };

    return await Connection.put(Macros.putURL + "users/resetPassword", payload);
}

export async function newUser(name: string, surname: string, email: string, password: string, password2: string) {
    const payload = {
        "name": name,
        "surname": surname,
        "email": email,
        "password": password,
        "password2": password2,
    };

    return await Connection.post(Macros.postURL + "users/newUser", payload);
}

export async function activateUser(passCode: string) {
    const payload = { "password_code": passCode };

    return await Connection.put(Macros.putURL + "users/activate", payload);
}

export async function editNotifications(userID: string, notifications: number) {
    const payload = { "user_id": userID, "notifications": notifications };

    return await Connection.put(Macros.putURL + "users/editNotifications", payload);
}

export async function redeemCode(userID: string, code: string) {
    const payload = {
        "user_id": userID,
        "code": code,
    };

    return await Connection.post(Macros.postURL + "books/redeemCode", payload);
}

export async function getUser(userID: string) {
    return await Connection.get(Macros.getURL + "users/read/" + userID);
}

export async function editUser(payload: any) {
    return await Connection.put(Macros.putURL + "users/editUser", payload);
}

export async function deleteUser(userID: string) {
    const payload = { "user_id": userID };
    return await Connection.delete(Macros.deleteURL + "users/deleteUser", payload);
}

export async function checkUserRole(userID: string, email: string) {
    return await Connection.get(`${Macros.getURL}users/canAccess/${userID}/${email}`);
}

export async function authorizeByToken(token: string) {
    const headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
    };

    return await fetch("https://approfondimenti.maggiolicloud.it/core/api/magento/customer-connect-authorize", {
        method: "POST",
        headers: headersList,
    });
}

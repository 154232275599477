import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { H3, BackofficePageLoginHeader, Button, Detail, Form, Grid, InputPassword, InputText, Banner } from "@maggioli-design-system/react";
import { scrollTop } from "../utils/Functions";
import * as UserService from "../services/UserServices";
import { IUser } from "../interfaces/IUser";
import { ISubmit } from "../interfaces/ISubmit";
import { setCookie } from "../utils/Macros";

const INIT_STAT: string = "";
const INIT_TEXT: string = "";
const accessTime: number = 1200;

export default function Login(): ReactElement {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [submit, setSubmit] = useState<ISubmit>({ status: INIT_STAT, text: INIT_TEXT });

    useEffect(() => {
        document.title = `Login | Approfondimenti`;
    }, []);

    // hanlder on click accedi
    const onClickLogin = () => {
        if (email === "" || password === "") {
            setSubmit({ "status": "error", "text": "Inserire email e password" });
            window.setTimeout(() => setSubmit({ status: INIT_STAT, text: INIT_TEXT }), 1500);
        } else {
            attemptLogin();
        }
    };

    const redirectAfterLogin = () => {
        const path = window.location.pathname !== "/login" ? window.location.pathname : "/myBooks/page/1";
        window.setTimeout(() => window.location.replace(path), accessTime);
    };

    // chiamata API login
    const attemptLogin = async () => {
        const res = await UserService.login(email, password, false);
        const data = await res.json();
        if (data.status) {
            const user: IUser = data.data;

            sessionStorage.clear();
            setCookie(
                "loggedUser",
                JSON.stringify({
                    email: user.email,
                    id: user.id,
                }),
            );

            setSubmit({ "status": "success", "text": "Accesso effettuato!" });
            redirectAfterLogin();
        } else {
            setSubmit({ "status": "error", "text": data.message });
        }
    };

    return (
        <Grid className="background-color-adjust-tone-19">
            <Grid className="my-20 xxl:px-80 xl:px-44 sm:px-16 px-3" columns="2">
                <Detail className="padding-small">
                    <H3>Attenzione</H3>
                    Tutti gli utenti che avevano un account su maggiolieditore.it/approfondimenti, per poter consultare i contenuti aggiuntivi devono
                    eseguire prima la procedura di "Cambio password" e successivamente accedere con la nuova password scelta. <br />
                    La mail da indicare deve essere la stessa utilizzata per l'account su maggiolieditore.it
                </Detail>
                <Grid className="background-color-adjust-tone-17 shadow-lg padding-normal border-radius-normal xxl:mx-16">
                    <Form
                        onSubmit={(e: ChangeEvent<HTMLFormElement>) => {
                            e.preventDefault();
                            onClickLogin();
                        }}
                    >
                        <BackofficePageLoginHeader
                            logo={`${process.env.PUBLIC_URL}/assets/images/logo-contenuti-aggiuntivi.svg`}
                            title="Approfondimenti"
                            description="Accedi al servizio"
                        />

                        <InputText
                            placeholder="Inserisci l'e-mail..."
                            icon="document-email"
                            value={email}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => setEmail(e.target.value)}
                        />

                        <InputPassword
                            onChange={(e: ChangeEvent<HTMLFormElement>) => setPassword(e.target.value)}
                            value={password}
                            placeholder="Inserisci la password..."
                        />

                        <Grid columns="2">
                            <Button type="submit">Accedi</Button>

                            <Link to="/users/register">
                                <Button width="fill" variant="secondary">
                                    Registrati
                                </Button>
                            </Link>
                        </Grid>
                    </Form>
                    {submit.status !== "" && (
                        <Banner status={submit.status} className="border-radius-small">
                            <Detail>{submit.text}</Detail>
                        </Banner>
                    )}
                    <Link to="/recover">
                        <Button size="small" width="fill" variant="secondary-outline" onClick={scrollTop}>
                            Recupera password
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
}

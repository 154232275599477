import React, { ReactElement } from "react";
import { Button, Card, H3, H4, Image, Row } from "@maggioli-design-system/react";
import { IHandleLoader } from "../interfaces/IHandleLoader";

interface Props {
    handleLoader: IHandleLoader;
}

const WIN_32_URL: string = "https://docbox.maggioli.it/owncloud/s/hB5xcbxaYCRxJHO/download";
const WIN_64_URL: string = "https://docbox.maggioli.it/owncloud/s/3IzW0nfq50hlsTD/download";
const LINUX_64_URL: string = "https://docbox.maggioli.it/owncloud/s/fFPXwA7mpoOxJQN/download";

export default function DownloadiLibroApp({ handleLoader }: Props): ReactElement {
    const onClickDownload = (os: string, version: number = 64) => {
        const element = document.createElement("a");
        element.download = `iLibroApp-${os}-${version}bit`;

        if (os === "win") {
            element.href = version === 64 ? WIN_64_URL : WIN_32_URL;
        } else if (os === "linux") {
            element.href = LINUX_64_URL;
        }

        document.body.appendChild(element);
        element.click();
    };

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <H3 className="text-center vertical-padding-xxsmall">Scarica l'app iLibro</H3>
            <div className="md:w-1/2 mx-auto mb-9 grid gap-3 w-full">
                Scegli la versione per il tuo sistema operativo (Windows o Linux). <br />
                Potrai consultare i volumi iLibro direttamente sul tuo computer.
                <Card>
                    <Row lastChild="to-right">
                        <Image src={`${process.env.PUBLIC_URL}/assets/images/windows-logo.png`} className="w-16" />
                        <H4>Windows</H4>
                        <div className="grid gap-2">
                            <Button icon="action-download" onClick={() => onClickDownload("win", 32)} horizontalPadding="none">
                                32bit
                            </Button>
                            <Button icon="action-download" onClick={() => onClickDownload("win")} horizontalPadding="none">
                                64bit
                            </Button>
                        </div>
                    </Row>
                </Card>
                <Card>
                    <Row lastChild="to-right">
                        <Image src={`${process.env.PUBLIC_URL}/assets/images/linux-logo.png`} className="w-16" />
                        <H4>Linux</H4>
                        <Button onClick={() => onClickDownload("linux")} icon="action-download" horizontalPadding="none">
                            64bit
                        </Button>
                    </Row>
                </Card>
            </div>
        </div>
    );
}

import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Banner, Card, Detail, Grid, H1, H3, H5, Image, Row, Button } from "@maggioli-design-system/react";
import RenderBooks from "../components/RenderBooks";
import * as BookService from "../services/BookServices";
import { IBook } from "../interfaces/IBook";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import { useWindowSize } from "../utils/Functions";
import { useNavigate } from "react-router-dom";

interface Props {
    handleLoader: IHandleLoader;
}

export default function Home({ handleLoader }: Props): ReactElement {
    const [books, setBooks] = useState<IBook[]>([]);
    const windowSize = useWindowSize();
    const history = useNavigate();

    useEffect(() => {
        document.title = "Approfondimenti Libri Maggioli";
        loadLastBooks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadLastBooks = async () => {
        handleLoader.setLoading(true);
        const res = await BookService.getLastBooks(6);
        const data = await res.json();
        handleLoader.setLoading(false);
        if (data.status) {
            setBooks(data.data);
        }
    };

    const loginText = (className: string = "") => (
        <Grid columns="1" gutter="xxsmall" className={className}>
            <H3>Accedi</H3>
            <Detail>Entra con il tuo account per iniziare ad aggiungere libri o visualizzare quelli aggiunti in precedenza.</Detail>
        </Grid>
    );

    const loginButtons = () => (
        <Grid columns="1">
            <Link to="/login">
                <Button width="fill" variant="primary-outline" onClick={() => {}}>
                    Accedi
                </Button>
            </Link>

            <Link to="/users/register">
                <Button width="fill" variant="success" onClick={() => {}}>
                    Registrati
                </Button>
            </Link>
        </Grid>
    );

    const prontuarioImage = () => <Image src={`${process.env.PUBLIC_URL}/assets/images/prontuariocds.png`} className="w-2/3 mr-12" />;
    const prontuarioText = () => (
        <Detail>
            <H5>PRONTUARIO DELLE VIOLAZIONI AL CODICE DELLA STRADA VIII EDIZIONE 2021</H5>
            <br />
            L’acquisto del presente volume include l’accesso al sito www.prontuariocodicedellastrada.it per un costante aggiornamento fino al 31
            dicembre 2021.
            <br />
            Con il medesimo codice, allegato a questo volume, potrete accedere sia ai contenuti del sito sia alla versione completa della App, previa
            registrazione. <br />
            <br />
            Per procedere al download della App su smartphone o tablet, vai sul sito www.prontuariocodicedellastrada.it, clicca sul banner "Consulta
            il prontuario sul tuo smartphone - scarica l’App" e, successivamente, clicca "Disponibile su Google Play" o "Disponibile su App store",
            per scaricare la App.
        </Detail>
    );

    const videoHeight: string = windowSize > 575 ? "220" : (windowSize * 0.4).toString();
    const videoWidth: string = windowSize > 575 ? "450" : (windowSize * 0.7).toString();

    const onClickResetPassword = () => {
        history("/recover");
    };

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <div className="view-limit">
                <H1 className="text-center vertical-padding-large">Approfondimenti Libri Maggioli</H1>
            </div>
            <Banner className="rounded-xl mb-10 view-limit">
                <Detail className="-mt-2">
                    <H3>Benvenuto!</H3>
                    Se avevi un account su approfondimenti.maggioli.it, puoi accedere al nuovo portale utilizzando la stessa email. <br />
                    Prima di fare ciò, richiedi un reset della password
                    <strong onClick={() => onClickResetPassword()} className="cursor-pointer">
                        {" "}
                        cliccando qui.
                    </strong>{" "}
                    <br />
                    <strong>Nota:</strong> sono stati importati i contenuti solo delle edizioni ancora valide.
                </Detail>
            </Banner>

            <Grid className="view-limit mb-10" columns="1">
                <Grid columns="2" gutter="xlarge" rows="fit-vertically">
                    <Card borderRadius="small" className={windowSize > 1024 ? "mr-16" : ""}>
                        {windowSize >= 768 ? (
                            <Row>
                                {loginText("w-2/3 mr-4")}
                                {loginButtons()}
                            </Row>
                        ) : (
                            <Grid>
                                {loginText()}
                                {loginButtons()}
                            </Grid>
                        )}
                    </Card>

                    <Card borderRadius="small" className={windowSize > 1024 ? "mr-24" : ""}>
                        <iframe
                            src="https://www.youtube-nocookie.com/embed/msoR_F9ejZ8"
                            width={videoWidth}
                            height={videoHeight}
                            title="Video player"
                            frameBorder="0"
                            allowFullScreen
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                    </Card>
                </Grid>

                <Card borderRadius="small">
                    {windowSize >= 768 ? (
                        <Row>
                            {prontuarioImage()}
                            {prontuarioText()}
                        </Row>
                    ) : (
                        <Grid>
                            {prontuarioImage()}
                            {prontuarioText()}
                        </Grid>
                    )}
                </Card>
                <H3 className="mt-10">Ultimi libri aggiunti</H3>
                <Grid columns="3" gutter="xlarge" rows="fit-vertically">
                    <RenderBooks data={books} />
                </Grid>
            </Grid>
        </div>
    );
}

import { IArea } from "../interfaces/IArea";
import { IAttach } from "../interfaces/IAttach";
import { downloadAttach, processWatermark, saveLog } from "../services/AttachmentServices";
import { Download, Card, H4, Grid } from "@maggioli-design-system/react";
import { getCookie } from "../utils/Macros";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import { saveFile } from "../utils/Functions";

interface Props {
    attach: IAttach;
    areas: IArea[];
    bookID?: string;
    loader: IHandleLoader;
}

export default function DownloadAttach({ attach, areas, bookID = "", loader }: Props) {
    const category =
        areas.filter((a) => a.id === attach.area_id).length > 0 ? areas.filter((a) => a.id === attach.area_id).pop().title : "Contenuti aggiuntivi";

    const loggedUser: string = getCookie("loggedUser");
    const creationDay: string = attach.last_edit.split(" ")[0];
    const creationDate = new Date(creationDay);
    const monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);
    const newTag = creationDate > monthAgo ? "Novità" : "";

    const saveViewLog = (attachID: string) => {
        if (loggedUser === null) {
            return;
        }

        const data = {
            "attach_id": attachID,
            "user_id": JSON.parse(loggedUser).id,
        };
        saveLog(data);
    };

    const downloadAttachment = async (attachID: string) => {
        loader.setLoading(true);
        const res = await downloadAttach(attachID);
        if (!res.ok) {
            return;
        }
        const data = await res.blob();
        saveFile(data, `${attach.title}.${attach.guid.split(".").pop()}`);
        loader.setLoading(false);
    };

    const onClickWatermarkPDF = async (attachID: string) => {
        if (loggedUser === null) {
            return;
        }

        const data = new FormData();
        data.append("id", attachID);
        data.append("email", JSON.parse(loggedUser).email);

        loader.setLoading(true);
        const res = await processWatermark(data);
        const dataWat = await res.json();
        loader.setLoading(false);
        if (dataWat.status) {
            const element = document.createElement("a");
            // element.setAttribute('target', '_blank');
            element.href = "https://approfondimenti.maggiolicloud.it" + dataWat.data;
            document.body.appendChild(element);
            element.click();
        }
    };

    const onClickDownload = () => {
        saveViewLog(attach.id);

        if (attach.watermark === "1") {
            onClickWatermarkPDF(attach.id);
            return;
        }

        if (attach.type === "video") {
            window.open(attach.guid, "_blank");
            return;
        }
        downloadAttachment(attach.id);
    };

    const name: string = attach.custom_title !== undefined ? attach.custom_title : attach.title;

    if (attach.type === "video" && attach.description.includes("iframe")) {
        return (
            <Card>
                <Grid gutter="xsmall" onClick={() => attach.id}>
                    <H4>{name}</H4>
                    <p dangerouslySetInnerHTML={{ __html: attach.description.replaceAll("fullscreen;", "") }} />
                </Grid>
            </Card>
        );
    }

    const fileTitle: string = attach.type !== "video" ? `${name}.${attach.guid.split(".").pop()}` : name + ".mp4";

    return (
        <div>
            <Download
                onClick={() => onClickDownload()}
                showExt={false}
                fileName={fileTitle}
                target="_blank"
                category={category}
                newTag={newTag}
                className="shadow-lg hover:shadow-xl"
            />
        </div>
    );
}

import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import { Banner, Detail, Grid, H3, InputText, Paginator, Row } from "@maggioli-design-system/react";
import RenderBooks from "../components/RenderBooks";
import * as BookService from "../services/BookServices";
import { IBook } from "../interfaces/IBook";
import { Sidebar } from "../components/Sidebar";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import { scrollTop, useWindowSize } from "../utils/Functions";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { getCookie, ILIBRO_ROLE } from "../utils/Macros";
import { IUserRole } from "../interfaces/IUser";

interface Props {
    handleLoader: IHandleLoader;
    user: IUserRole;
}

export default function MyBooks({ handleLoader, user }: Props): ReactElement {
    const { page } = useParams();
    const history = useNavigate();
    const windowSize = useWindowSize();

    const loggedUser: string = getCookie("loggedUser");
    const loggedUserID: string = loggedUser !== null ? JSON.parse(loggedUser).id : "";
    const RESULTS_PER_PAGE: number = 10;
    const IS_ILIBRO_ROLE: boolean = user.role === ILIBRO_ROLE;
    const currentPage: number = page === undefined ? 1 : parseInt(page);

    const [writing, setWriting] = useState<boolean>(false);
    const [books, setBooks] = useState<IBook[]>([]);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [pages, setPages] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>("");

    let timeID = useRef<any>(null);
    let time = useRef<number>(0);

    useEffect(() => {
        document.title = "I tuoi libri | Approfondimenti";
        handleLoader.setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (firstLoad) {
            return;
        }

        setWriting(true);
        manageTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    useEffect(() => {
        if (writing) {
            return;
        }
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [writing]);

    useEffect(() => {
        loadBooks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const loadBooks = async () => {
        firstLoad && setFirstLoad(false);

        if (IS_ILIBRO_ROLE) {
            const res = await BookService.getILibri(currentPage, RESULTS_PER_PAGE);
            const data = await res.json();
            if (data.status) {
                setPages(data.total_pages);
                setBooks(data.data);
            }
            return;
        }

        if (user.isWorker) {
            const res = await BookService.getBooks(currentPage, RESULTS_PER_PAGE);
            const data = await res.json();
            if (data.status) {
                setPages(data.total_pages);
                setBooks(data.data);
            }
        } else {
            const res = await BookService.getRedeemedBooks(loggedUserID);
            const data = await res.json();
            if (data.status) {
                setBooks(data.data);
            }
        }
    };

    const search = async () => {
        if (!user.isWorker) {
            return;
        }
        let res = null;
        if (searchText.length > 2 && IS_ILIBRO_ROLE) {
            res = await BookService.searchILibri(searchText, 1, RESULTS_PER_PAGE);
        }

        if (searchText.length > 2 && !IS_ILIBRO_ROLE) {
            res = await BookService.searchBooks(searchText, 1, RESULTS_PER_PAGE);
        }

        if (searchText.length <= 2 && IS_ILIBRO_ROLE) {
            res = await BookService.getILibri(currentPage, RESULTS_PER_PAGE);
        }

        if (searchText.length <= 2 && !IS_ILIBRO_ROLE) {
            res = await BookService.getBooks(currentPage, RESULTS_PER_PAGE);
        }

        const data = res !== null ? await res.json() : {};
        if (data.status) {
            setPages(data.total_pages);
        }

        setBooks(data.data);
    };

    const onClickNextPage = () => {
        currentPage < pages && history(`/myBooks/page/${currentPage + 1}`);
        scrollTop();
    };

    const onClickPrevPage = () => {
        currentPage > 1 && history(`/myBooks/page/${currentPage - 1}`);
        scrollTop();
    };

    const onClickPage = (newPage: number) => {
        history(`/myBooks/page/${newPage}`);
        scrollTop();
    };

    const paginator = (className: string = "") => {
        if (writing) {
            return <div></div>;
        }
        return (
            <Paginator
                className={className}
                pages={pages}
                currentPage={currentPage}
                onClick={onClickPage}
                onClickPrev={onClickPrevPage}
                onClickNext={onClickNextPage}
            />
        );
    };

    const manageTimer = () => {
        const timeSearch: number = 500;
        if (timeID.current === null) {
            timeID.current = setInterval(function () {
                if (time.current < timeSearch) {
                    time.current = time.current + timeSearch;
                } else {
                    time.current = 0;
                    setWriting(false);
                    clearInterval(timeID.current);
                    timeID.current = null;
                }
            }, timeSearch);
        } else {
            time.current = 0;
        }
    };

    const searchComponent = (
        <InputText
            value={searchText}
            placeholder="Cerca per titolo o isbn..."
            className="py-10 px-1"
            onChange={(e: ChangeEvent<HTMLFormElement>) => setSearchText(e.target.value)}
            icon="data-search"
        />
    );

    return (
        <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
            <div className="view-limit pt-8 pb-10 lg:pb-8 py-10">
                <Grid className="lg:grid-cols-3" gutter="xlarge">
                    <div className="my-8 lg:col-span-2">
                        <H3>I tuoi libri</H3>

                        {user.isWorker && windowSize >= 768 && (
                            <Row lastChild={books.length > 0 ? "to-right" : ""}>
                                <div>{searchComponent}</div>
                                {books.length > 0 && paginator()}
                            </Row>
                        )}

                        {user.isWorker && windowSize < 768 && (
                            <Grid gutter="none">
                                {searchComponent}
                                {books.length > 0 && paginator("-mx-8")}
                            </Grid>
                        )}

                        {books.length > 0 && !user.isWorker && (
                            <Banner className="border-radius-normal">
                                <Detail>
                                    Per aggiungere un nuovo libro alla tua libreria inserisci il codice che trovi sul volume nel box qui accanto e
                                    clicca "Aggiungi".
                                </Detail>
                            </Banner>
                        )}

                        <Grid className="mt-4" columns={books.length > 0 ? "2" : "1"} gutter="xlarge" rows="fit-vertically">
                            {books.length === 0 && !writing && (
                                <Banner className="border-radius-normal" status="warning">
                                    <Detail>
                                        {searchText.length === 0
                                            ? "Al momento la tua libreria è vuota! Per aggiungere un libro inserisci il relativo" +
                                              'codice che trovi sul volume nella nel box qui accanto e clicca "Aggiungi".'
                                            : "Non sono stati trovati volumi."}
                                    </Detail>
                                </Banner>
                            )}

                            {!writing ? (
                                <RenderBooks data={books} />
                            ) : (
                                <div className="">
                                    <ThreeDots color="#0041B9" />
                                </div>
                            )}
                        </Grid>

                        {user.isWorker && <Row lastChild="to-right">{books.length > 0 ? paginator() : null}</Row>}
                    </div>

                    {/* Sidebar */}
                    <div className="relative">
                        <Sidebar className="lg:grid xxl lg:col-span-1 mt-14 sticky top-24" user={user} />
                    </div>
                </Grid>
            </div>
        </div>
    );
}

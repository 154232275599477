
export const emailValidation = (email: string) => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/;
    return pattern.test(String(email).toLowerCase());
}

export const nameSurnameValidation = (name: string, surname: string) => {
    const pattern = /^[A-Za-z\s]*$/;
    console.log(pattern.test(name) && pattern.test(surname))
    return pattern.test(name) && pattern.test(surname);
}

export const passwordValidation = (password: string, password2: string): boolean => {
    if (password.length < 6 || password2.length < 6) {
        return false;
    }
    return password === password2;
}
import React, { ReactElement, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Banner, Button, Grid, H2, Hr, Icon, Paginator, Row } from "@maggioli-design-system/react";
import { IAttach } from "../interfaces/IAttach";
import { IArea } from "../interfaces/IArea";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import { useWindowSize } from "../utils/Functions";
import Informativa from "../components/Informativa";
import DownloadAttach from "../components/DownloadAttach";
import { getCookie } from "../utils/Macros";
import { getContentInQRCode } from "../services/QRService";

interface Props {
    handleLoader: IHandleLoader;
}

interface IQRCode {
    id: string;
    description: string;
    attachments: IAttach[];
    area: IArea;
}

export default function BookPage({ handleLoader }: Props): ReactElement {
    const { id } = useParams();
    const history = useNavigate();
    const windowSize = useWindowSize();
    const loggedUser: string = getCookie("loggedUser");
    const loggedUserID: string = loggedUser !== null ? JSON.parse(loggedUser).id : "";
    const RESULTS: number = 10;

    const [qrCode, setqrCode] = useState<IQRCode>(null);
    const [filteredAttach, setFilterAttach] = useState<IAttach[]>([]);
    const [informativa, setInformativa] = useState<boolean>(false);

    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        if (loggedUser === null) {
            return;
        }
        loadQRCode();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadQRCode = async () => {
        handleLoader.setLoading(true);
        const res = await getContentInQRCode(id, loggedUserID);
        handleLoader.setLoading(false);
        const data = await res.json();
        if (!data.status) {
            return;
        }
        setqrCode(data.data);
        setFilterAttach(data.data.attachments);
        updateAttach(data.data.attachments);
    };

    // Aggiorna gli allegati mostrati e il paginatore
    const updateAttach = (data: IAttach[]) => {
        setFilterAttach(data);
        setPages(calculatePages(data.length));
        setCurrentPage(1);
    };

    // Ritorna il n. di pagine a seconda del numero di allegati
    const calculatePages = (numAttach: number): number => {
        const numPages: number = Math.floor(numAttach / RESULTS);
        return numAttach % RESULTS !== 0 ? numPages + 1 : numPages;
    };

    // Funzioni paginatore
    const onClickNextPage = () => {
        currentPage < pages && setCurrentPage(currentPage + 1);
    };
    const onClickPrevPage = () => {
        currentPage > 1 && setCurrentPage(currentPage - 1);
    };
    const onClickPage = (newPage: number) => {
        setCurrentPage(newPage);
    };

    // Stile informativa diritto d'autore
    const informativaStyle = {
        height: informativa ? "100%" : "0px",
        opacity: informativa ? "100%" : "0%",
        transition: "all 0.3s ease-out",
    };

    const informativaButton = () => {
        return (
            <div>
                <Button
                    variant="primary-outline"
                    onClick={() => setInformativa(!informativa)}
                    icon={informativa ? "navigation-hide" : "navigation-show"}
                >
                    Informativa sul diritto d'autore
                </Button>
            </div>
        );
    };

    const start: number = (currentPage - 1) * RESULTS;
    const end: number = start + RESULTS;

    if (qrCode === null) {
        return (
            <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
                <div className="view-limit pt-8 pb-0 lg:pb-8">
                    <Grid>
                        <Row>
                            <H2>404 - QR code non trovato</H2>
                        </Row>
                        <div>
                            <Button variant="primary-outline" icon="document-book" onClick={() => history("/")}>
                                Vai alla home
                            </Button>
                        </div>
                    </Grid>
                </div>
            </div>
        );
    }

    return (
        <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
            <div className="view-limit pt-8 pb-0 lg:pb-8">
                <Grid className="lg:grid-cols-5" gutter="xlarge">
                    <Grid rows="fit-vertically" className="lg:col-span-4" gutter="small">
                        <Row>
                            <Icon name={qrCode.area === null ? "document" : "tag"} />
                            <H2>{qrCode.area === null ? qrCode.attachments[0].title : qrCode.area.title}</H2>
                        </Row>
                        {windowSize > 768 ? <Row>{informativaButton()}</Row> : <Grid>{informativaButton()}</Grid>}

                        <div style={informativaStyle}>{informativa && <Informativa showLine={false} />}</div>

                        <Hr spacing="xsmall" />

                        <div>
                            {/* Paginatore allegati */}
                            {pages > 1 && (
                                <Row lastChild="to-right" className="">
                                    <Paginator
                                        pages={pages}
                                        currentPage={currentPage}
                                        onClick={onClickPage}
                                        onClickPrev={onClickPrevPage}
                                        onClickNext={onClickNextPage}
                                    />
                                </Row>
                            )}

                            {filteredAttach.length > 0 ? (
                                <Grid className="my-5">
                                    {filteredAttach.slice(start, end).map((elem, i: number) => (
                                        <div key={i}>
                                            <DownloadAttach attach={elem} areas={[]} loader={handleLoader} />
                                        </div>
                                    ))}
                                </Grid>
                            ) : (
                                <Banner status="warning" className="w-full rounded-2xl text-secondary text-secondary--detail mb-5">
                                    La ricerca non ha prodotto risultati.
                                </Banner>
                            )}

                            {pages > 1 && (
                                <Row lastChild="to-right" className="">
                                    <Paginator
                                        pages={pages}
                                        currentPage={currentPage}
                                        onClick={onClickPage}
                                        onClickPrev={onClickPrevPage}
                                        onClickNext={onClickNextPage}
                                    />
                                </Row>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

import { Banner, Detail, Button, Form, InputText, Textarea, Radio } from "@maggioli-design-system/react";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import { ISubmit } from "../interfaces/ISubmit";
import * as Validator from "../utils/Validator";
import * as Macros from "../utils/Macros";
import * as Privacy from "../services/Privacy";
import { IField, IPrivacy } from "../interfaces/IPrivacy";
import { IForm } from "../interfaces/IForm";

interface Props {
    handleLoader: IHandleLoader;
}

const INIT_TEXT: string = "";
const INIT_STAT: string = "";

export default function ContactForm({ handleLoader }: Props): ReactElement {
    const [form, setForm] = useState<IForm>(null);
    const [commerciale, setCommerciale] = useState<string>(null);
    const [submit, setSubmit] = useState<ISubmit>({ text: INIT_TEXT, status: INIT_STAT });

    useEffect(() => {
        getForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getForm = async () => {
        handleLoader.setLoading(true);
        const res = await Privacy.getForms(Macros.privacyToken);
        const data = await res.json();
        if (data.form === undefined || data.form.length === 0) {
            return;
        }
        setForm(data.form.filter((elem: IForm) => elem.nome === "contatti").pop());
        handleLoader.setLoading(false);
    };

    const privacyHandler = (value: string, privacyName: string) => {
        switch (privacyName) {
            case "commerciale":
                setCommerciale(value);
                break;
            default:
                break;
        }
    };

    const privacyFields = (): JSX.Element[] => {
        return form.privacy.map((elem: IPrivacy, index: number) => (
            <div key={index}>
                <Detail className="privacy-consent" htmlTag={"div"}>
                    <p dangerouslySetInnerHTML={{ __html: elem.description }} />
                    {elem.fields.map((item: IField, i: number) => (
                        <Radio
                            key={i}
                            name={item.name}
                            value={item.options.value}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => privacyHandler(e.target.value, item.name)}
                        >
                            {item.options.text}
                        </Radio>
                    ))}
                </Detail>
            </div>
        ));
    };

    const submitForm = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const resIP = await Privacy.getMyIP();
        if (!resIP.ok) {
            setSubmit({ text: "Errore nel recupero dell'IP", status: "error" });
            return;
        }
        const dataIP = await resIP.json();

        if (commerciale === null) {
            setSubmit({ text: "Tutti i campi sono obbligatori.", status: "error" });
            return;
        }

        const formData = new FormData(e.target);
        const data = {};
        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }

        if (!Validator.nameSurnameValidation(data["nome"], data["cognome"])) {
            setSubmit({ text: "Nome/cognome non valido. \nCaratteri ammessi: lettere e spazi.", status: "error" });
            return;
        }

        data["token"] = Macros.privacyToken;
        data["ip"] = dataIP.ip;

        if (!Validator.emailValidation(data["email"])) {
            setSubmit({ text: "Email non valida.", status: "error" });
            return;
        }

        const payload = {
            email: "clienti.editore@maggioli.it", // email amministrazione
            // from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "Approfondimenti Maggiolicloud", //nome sito
            // replyTo: formData.get("email"), //email inserita dall'utente
            subject: "Richiesta Assistenza Approfondimenti Maggioli",
            description: `<h1>Richiesta di assistenza da parte di ${formData.get("nome")} ${formData.get("cognome")}</h1>
                        <p>Messaggio:<br> ${formData.get("message")} </p> <br> Email inviata da: ${formData.get("email")}`
            // responseParams: {
            //     to: formData.get("email"), //email inserita dall'utente
            //     message: "Grazie per averci contattato. A breve riceverai una risposta da parte dell'amministrazione di Approfondimenti.",
            // },
            // params: {
            //     ...Macros.emailParams,
            // },
        };

        handleLoader.setLoading(true);
        const resPrivacy = await Privacy.saveConsentInPrivacy(data);
        if (!resPrivacy.ok) {
            return;
        }

        const resSub = await Privacy.sendEmail(payload);
        if (resSub.ok) {
            handleLoader.setLoading(false);
            setSubmit({ text: "Richiesta inviata con successo!", status: "success" });
            sessionStorage.setItem("thankYouType", "contact");
            window.setTimeout(() => window.location.replace("/thank-you"), 2000);
        }
    };

    return (
        <div>
            {form !== null && (
                <Form className="view-limit" columns="1" onSubmit={(e: ChangeEvent<HTMLFormElement>) => submitForm(e)}>
                    {form.fields
                        .filter((elem) => elem.type !== "hidden" && elem.input !== "textarea")
                        .map((elem, index) => {
                            const loggedUser: string = Macros.getCookie("loggedUser");
                            const value = loggedUser !== null && elem.name === "email" ? JSON.parse(loggedUser).email : "";
                            return <InputText value={value} key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />;
                        })}

                    {form.fields
                        .filter((elem) => elem.input === "textarea")
                        .map((elem, index) => (
                            <Textarea key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />
                        ))}

                    {form.fields
                        .filter((elem) => elem.type === "hidden")
                        .map((elem, index) => (
                            <input key={index} name={elem.name} value={elem.options.value} type="hidden" />
                        ))}
                    <input name="trattamento" value="1" type="hidden" />
                    <input name="servizio" value={form.servizio} type="hidden" />

                    {/* Consenso informato */}
                    <Detail htmlTag={"div"}>
                        <strong>
                            <p className="privacy-consent" dangerouslySetInnerHTML={{ __html: form.consenso_informato }} />
                        </strong>
                    </Detail>
                    {privacyFields()}
                    <div>
                        <Button type="submit">Invia</Button>
                    </div>
                    {submit.status !== "" && (
                        <Banner status={submit.status} className="border-radius-small">
                            <Detail>{submit.text}</Detail>
                        </Banner>
                    )}
                </Form>
            )}
        </div>
    );
}

import { ReactElement, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Detail, H3, H4 } from "@maggioli-design-system/react";
import { activateUser } from "../services/UserServices";

export default function Activate(): ReactElement {
    const { passCode } = useParams();

    const [activated, setActivated] = useState<boolean>(null);
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        enableAccount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const enableAccount = async () => {
        const res1 = await activateUser(passCode);
        const data1 = await res1.json();
        if (data1.status) {
            setActivated(data1.status);
            setMessage(data1.message);
        }
    };

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <H3 className="text-center vertical-padding-xxsmall">Approfondimenti Libri Maggioli</H3>
            {activated && (
                <div>
                    <H4 className="view-limit text-left mt-14">Grazie</H4>
                    <Detail className="view-limit vertical-padding-xxsmall">
                        Attivazione account avvenuta con successo! <br />
                        Maggioli ti dà il benvenuto al nostro portale di Approfondimenti. All'interno di approfondimenti.maggiolicloud.it potrai in
                        libertà usufruire dell'ampio database di approfondimenti realizzati appositamente per Te. <br />
                        <br />
                        Buona navigazione,
                        <br />
                        Lo staff di Maggioli Editore.
                    </Detail>
                    <div className="view-limit vertical-padding-xxsmall mt-4">
                        <Link to="/login">
                            <Button variant="primary-outline">Accedi</Button>
                        </Link>
                    </div>
                </div>
            )}
            {activated === false && (
                <div>
                    <H4 className="view-limit text-left mt-14">Errore</H4>
                    <Detail className="view-limit vertical-padding-xxsmall">{message}</Detail>
                </div>
            )}
        </div>
    );
}

import Connection from "../utils/Connection";
import { privacyURL, postURL } from "../utils/Macros";

const headers = new Headers();
const user: string = "api";
const password: string = "4BVFp>AK{S>e=mJ";

const setHeader = () => {
    headers.set("Authorization", "Basic " + btoa(`${user}:${password}`));
    headers.set("Content-Type", "application/json");
};

export async function getForms(token: string) {
    const data = { token: token };
    return await fetch(`${privacyURL}/get/form`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(data),
    });
}

export async function saveConsentInPrivacy(data: any) {
    return await fetch(`${privacyURL}/api/v1/post/send`, {
        method: "POST",
        body: JSON.stringify(data),
    });
}

export async function sendDataToSubscriptions(data: any) {
    setHeader();
    return await fetch("https://subscriptions.maggiolicloud.it/email", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: headers,
        body: JSON.stringify(data),
    });
}

export async function postBiblioApp(data: any) {
    setHeader();
    return await fetch("https://subscriptions.maggiolicloud.it/login/biblioteca/app", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: headers,
        body: JSON.stringify(data),
    });
}

export async function sendEmail(data: any) {
    return await Connection.post(postURL + "email/send", data);
}

export async function getMyIP() {
    setHeader();
    return await fetch(`https://subscriptions.maggiolicloud.it/helper/ip`, {
        method: "GET",
        headers: headers,
    });
}

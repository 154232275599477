import Singleton from "../utils/Singleton";

export const getRedaBook = async (isbn: string) => {
    const redaToken = Singleton.getInstance();
    const res = await fetch(`https://sisred.maggiolicloud.it/api/libro/${isbn}`, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${redaToken}`,
        },
    });
    if (!res.ok) {
        return false;
    }
    const data = await res.json();
    return data;
};

import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Grid, Row, Detail, H5 } from "@maggioli-design-system/react";
import { IBook } from "../interfaces/IBook";
import * as Macros from "../utils/Macros";

interface CustomBook {
    data: IBook;
}

const Book = ({ data }: CustomBook): JSX.Element => {
    const onClickLibro = () => {
        sessionStorage.setItem("book", JSON.stringify(data));
    };

    const isPublished: boolean = data.published === "1";
    const bookSlug: string = data.id;
    const bookImage: string =
        data.guid === "" || data.guid === null ? `${process.env.PUBLIC_URL}/assets/images/no-book-cover.jpg` : Macros.uploads + data.guid;
    return (
        <Grid gutter="small">
            <Row gutter="small" className="items-start">
                {isPublished ? (
                    <Link className="min-w-8 overflow-visible" to={`/book/${bookSlug}`} onClick={onClickLibro}>
                        <LazyLoadImage className="rounded-md shadow-lg w-32 hover:shadow-xl" src={bookImage} />
                    </Link>
                ) : (
                    <LazyLoadImage className={`rounded-md min-w-8 shadow-lg w-32 opacity-40`} src={bookImage} />
                )}

                <Grid gutter="xxsmall" className="py-3">
                    {isPublished ? (
                        <Link key={data.title} to={`/book/${bookSlug}`} onClick={onClickLibro}>
                            <H5>{data.title.length > 70 ? `${data.title.slice(0, 70)}...` : data.title}</H5>
                        </Link>
                    ) : (
                        <H5>{data.title.length > 70 ? `${data.title.slice(0, 70)}...` : data.title}</H5>
                    )}

                    <Detail>ISBN: {data.isbn}</Detail>
                    {!isPublished && <Detail className="italic">Non pubblicato</Detail>}
                </Grid>
            </Row>
        </Grid>
    );
};

export default Book;

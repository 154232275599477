import Connection from "../utils/Connection";
import * as Macros from "../utils/Macros";

export async function getAttachmentsByBook(bookID: string) {
    return await Connection.get(Macros.getURL + "books/attachments/" + bookID);
}

export async function downloadAttach(attachID: string) {
    return await Connection.get(Macros.getURL + "attachments/downloadCheckCodes/" + attachID);
}

export async function processWatermark(data: any) {
    return await Connection.upload(Macros.postURL + "watermark/index", data);
}

export async function saveLog(data: any) {
    return await Connection.post(Macros.postURL + "attachments/saveLog", data);
}

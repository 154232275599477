import { useEffect } from "react";
import { Link } from "react-router-dom";
import { H2, H5 } from "@maggioli-design-system/react";

const NotFound = (): JSX.Element => {
    useEffect(() => {
        document.title = `Errore 404 non trovato | Approfondimenti`;
    }, []);

    return (
        <div className="px-8 py-10 background-color-adjust-tone-19">
            <div className="view-limit">
                <H2 className="text-center vertical-padding-large">Ops! 404 - Pagina non trovata</H2>
                <Link to="/">
                    <H5 className="text-center">Torna alla home</H5>
                </Link>
            </div>
        </div>
    );
};

export default NotFound;

export const contact = "Grazie per averci contattato. <br/>Riceverai quanto prima una risposta dall'amministrazione di Approfondimenti. <br/>";

export const guida = "Grazie per averci contattato. <br/>Riceverai, entro pochi giorni, una e-mail dalla segreteria di guidaentilocali.it, contenente username e password per accedere al sito. <br/>";

export const registration = "Registrazione effettuata con successo! <br/>Riceverai un'email contentente il link di attivazione del tuo account.";

export const getText = (type: string) => {
    switch(type) {
        case 'contact': return contact; 
        case 'reg': return registration;
        case 'guida': return guida;
        default: return '';
    }
}
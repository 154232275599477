import Connection from '../utils/Connection';
import * as Macros from '../utils/Macros';
import Singleton from '../utils/Singleton';

const getParam = (): RequestInit => {
    const myHeaders: Headers = new Headers();
    myHeaders.append("Authorization", `Bearer ${Singleton.getInstance()}`)
    myHeaders.append("Accept", `application/json`)
    myHeaders.append("Content-Type", `application/json`)

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders
    };

    return requestOptions
}

export async function getBooks(page: number = 1, resultsPerPage: number = 30) {
    return await Connection.get(Macros.getURL + 'books/index/' + page + "/" + resultsPerPage);
}

export async function getILibri(page: number = 1, resultsPerPage: number = 30) {
    return await Connection.get(Macros.getURL + 'books/ilibro/' + page + "/" + resultsPerPage);
}

export async function getRedeemedBooks(userID: string) {
    return await Connection.get(Macros.getURL + 'books/getRedeemed/' + userID);
}

export async function getLastBooks(resultsPerPage: number) {
    return await Connection.get(Macros.getURL + 'books/getLasts/' + resultsPerPage);
}

export async function deleteCodeGuidaNormativa(code: string) {
    const payload = { 'code' : code }; 
    return await Connection.delete(Macros.deleteURL + 'books/deleteCodeGuidaNormativa', payload);
}

export async function saveLog(data: any) {
        
    await Connection.post(Macros.postURL + 'books/saveLog', data)
    .then(response => response.json())
    .then(data => {
        // console.log(data);
    })
    .catch(error => console.log(error))
}

export async function getBookByID(bookID: string) {
    return await Connection.get(Macros.getURL + 'books/read/' + bookID)
}

export async function searchBooks(pattern: string, page: number = 1, booksPerPage: number = 30) {
    return await Connection.get(Macros.getURL + 'books/s/' + pattern + '/' + page + '/' + booksPerPage);
}

export async function searchILibri(pattern: string, page: number = 1, booksPerPage: number = 30) {
    return await Connection.get(Macros.getURL + 'books/sILibro/' + pattern + '/' + page + '/' + booksPerPage);
}
// reda
export const getTree = async (id: number|string, callback: Function) => {
    try {
        const response = await fetch( `${Macros.reda.getTree}${id}`, getParam())
        if( response.ok )
        {
            const data = await response.json()
            callback(data)

        } else {
            callback(response.status)
        }
    } catch(e) {
        callback(e)
    }    
}

export const getChildrenTree = async (id: number, callback: Function) => {
    try {
        const response = await fetch( `${Macros.reda.getTree}${id}/children`, getParam())
        if( response.ok )
        {
            const data = await response.json()
            callback(data)

        } else {
            callback(response.status)
        }
    } catch(e) {
        callback(e)
    }    
}

export const getRedaDocuments = async (id: number, page: number = 1, size: number = 20, callback: Function) => {
    try {
        const response = await fetch( `${Macros.reda.getDocumens}${id}&size=${size}&page=${page}`, getParam())
        if( response.ok )
        {
            const data = await response.json()
            callback(data, response.headers.get("x-total-count"))

        } else {
            callback(response.status)
        }
    } catch(e) {
        callback(e)
    }    
}

export const downloadDocument = async (id: number, callback: Function) => {
    try {
        const response = await fetch( `${Macros.reda.download}${id}/download`, getParam())
        if( response.ok )
        {
            const data = await response.blob()
            callback(data)

        } else {
            callback(response.status)
        }
    } catch(e) {
        callback(e)
    }    
}

export const getProvvedimenti = async (id: number, page: number = 1, size: number = 20, callback: Function) => {
    try {
        const response = await fetch( `${Macros.reda.getProvvedimento}${id}/documenti?size=${size}&page=${page}`, getParam())
        if( response.ok )
        {
            const data = await response.json()
            callback(data, response.headers.get("x-total-count"))

        } else {
            callback(response.status)
        }
    } catch(e) {
        callback(e)
    }    
}
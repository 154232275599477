import React, { ReactElement } from 'react'
import {
    H3, 
    Grid,
} from '@maggioli-design-system/react'
import { IHandleLoader } from '../interfaces/IHandleLoader'
import ContactForm from '../components/ContactForm';
interface Props {
    handleLoader: IHandleLoader
}

export default function Contact({ handleLoader }: Props): ReactElement {
    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <H3 className="text-center vertical-padding-xxsmall">Contatti</H3>
            <Grid columns="3">
                <div></div>
                <div className="background-color-adjust-tone-17 padding-small border-radius-small">
                    <ContactForm handleLoader={handleLoader}/>
                </div>
                <div></div>
            </Grid>
        </div>
    )
}

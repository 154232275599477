import {useEffect, useState} from 'react'
import {
    Detail,
    Grid,
    Button,
    H3,
    H4,
    Hr,
    Paragraph,
    Card,
    Row,
    Icon,
} from '@maggioli-design-system/react'
import {  Link } from 'react-router-dom';
import ContactForm from '../components/ContactForm';
import { IHandleLoader } from '../interfaces/IHandleLoader';
import { getCookie } from '../utils/Macros';

interface Props {
    handleLoader: IHandleLoader
}

const Help = ({ handleLoader }: Props): JSX.Element => {

    const cardStyle = {
        cursor: 'pointer',
    }
    const isLogged: boolean = getCookie('loggedUser') !== null;

    const [context, setContext] = useState<string>('default');

    useEffect(() => {
        document.title = `Aiuto | Approfondimenti`;
    }, [])

    const stepCard = (icon: string, title: string, desc: string): JSX.Element => {
        const selected: string = title === context ? 'background-color-adjust-tone-16' : '';
        return <div onClick={() => setContext(title)} style={cardStyle}>
            <Card className={`color-brand-maggioli-05 hover:background-color-adjust-tone-17 ${selected}`}
                    padding="xsmall" boxShadow="box--interactive" >
                <Row>

                    {/* Icona */}
                    <Icon size="xlarge" name={icon}/>
                
                    {/* Info utente e codici generati */}
                    <Grid gutter="none" columns="1">
                        <Paragraph className="color-adjust-tone-04">{title}</Paragraph>
                        <Detail className="color-adjust-tone-04">{desc}</Detail>
                    </Grid>

                </Row>
            </Card>
        </div>
    }

    const registerContent = () =>  
        <Card rows="fit-vertically" className="lg:col-span-2 background-color-adjust-tone-19">
            <H4>Registrazione</H4>
            <Detail>Per poter riscattare il codice di approfondimento, è necessario un account registrato all’interno 
            della piattaforma. Utilizza il form di registrazione nella sezione dedicata per procedere. <br/><br/>
            Controlla il tuo account di posta elettronica con la quale hai effettuato la reigstrazione, troverai un 
            messaggio con un link di attivazione del tuo nuovo account.</Detail>
            <Link to="/users/register"><Button variant="primary-outline">Registrati</Button></Link>
        </Card>

    const loginContent = () =>  
        <Card rows="fit-vertically" className="lg:col-span-2 background-color-adjust-tone-19">
            <H4>Accedi con il tuo account</H4>
            Se sei già registrato, accedi al servizio utilizzando il tuo account personale.
            <Link to="/login"><Button variant="primary-outline">Accedi</Button></Link>
        </Card>

    const bookContent = () =>  
        <Card rows="fit-vertically" className="lg:col-span-2 background-color-adjust-tone-19">
            <H4>Cerca un libro tramite ISBN</H4>
            <Detail>In homepage, troverai un modulo per la ricerca del libro acquistato tramite codice ISBN. Il codice ISBN è situato 
            sul retro del libro, associato al codice a barre. <br/>
            Se il codice ISBN è corretto e il libro si trova all'interno del nostro database, 
            questo verrà mostrato come unico risultato e potrai aggiungerlo alla tua libreria virtuale: 
            rimarrà associato al tuo utente e potrai successivamente riscattare i relativi contenuti aggiuntivi.</Detail>
            <Link to='/'><Button variant="primary-outline">Vai alla home</Button></Link>
        </Card>

    const codeContent = () =>  
        <Card rows="fit-vertically" className="lg:col-span-2 background-color-adjust-tone-19">
            <H4>Riscatta codice di approfondimento</H4>
            <Detail>In homepage, inserisci nella barra laterale il codice per l'accesso ai contenuti aggiuntivi del volume di riferimento.
            All'interno della scheda del libro, troverai il modulo di ricerca per riscattare il codice di accesso ai contenuti aggiuntivi: 
            inserisci il codice nell'apposito campo per poterne usufruire. <br/> 
            Il codice può essere riscattato solo una volta in quanto è associato all'account 
            che lo riscatta: non inviare il codice a nessuno o rischierai di non poterlo utilizzare personalmente.</Detail>
            <Link to='/'><Button variant="primary-outline">Vai alla home</Button></Link>
        </Card>

    const recoverPasswordContent = () =>  
        <Card rows="fit-vertically" className="lg:col-span-2 background-color-adjust-tone-19">
            <H3>Recupera la password di accesso</H3>
            <Detail>Nel caso tu abbia dimenticato la tua password, 
            potrai impostarne una nuova dalla sezione di recupero password. <br/>
            Una volta avviata la procedura di recupero password, riceverai una email con un 
            link di accesso alla pagina di reimpostazione della password, accessibile solo tramite link 
            generato nella email che ti abbiamo inviato.</Detail>
            <Link to="/recover"><Button variant="primary-outline">Recupera password</Button></Link>
        </Card>

    const defaultContent = () =>  
        <Card rows="fit-vertically" className="lg:col-span-2 background-color-adjust-tone-17 text-center">
            <H3>Scegli una sezione</H3>
        </Card>
    
    const sections = [
        { icon: 'crud-create', title: 'Registrati' },
        { icon: 'security-fingerprint', title: 'Accedi' },
        { icon: 'document-book', title: 'Cerca un volume' },
        { icon: 'security-code', title: 'Riscatta un codice' },
    ];

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <H3 className="text-center vertical-padding-xxsmall">Aiuto e supporto</H3>
            <Detail className="text-center vertical-padding-xxsmall">
                Se hai problemi ad accedere al servizio
                questa sezione ti aiuterà a risolvere i problemi che hai riscontrato.
            </Detail>
            <div className="xl:ml-64 lg:ml-44">
                <H4 className="view-limit lg:px-8 text-left mt-14">
                    Come ottengo gli approfondimenti dei libri?
                </H4>
                <Grid className="view-limit lg:px-8 mt-3 lg:grid-cols-4" columns="4" gutter="" rows="fit-vertically">

                    <Grid className="lg:col-span-1" rows="fit-vertically">
                        { sections.slice(isLogged ? 2 : 0).map((s, i) => stepCard(s.icon, s.title, `STEP ${i + 1}`))}
                    </Grid>

                    { context === "Registrati" && registerContent() }
                    { context === 'Accedi' && loginContent() }
                    { context === 'Cerca un volume' && bookContent() }
                    { context === 'Riscatta un codice' && codeContent() }
                    { context === 'Recupera password' && recoverPasswordContent() }
                    { context === 'default' && defaultContent() }
            
                </Grid>

                <H4 className="view-limit lg:px-8 text-left mt-14">
                    Ti serve un altro tipo di aiuto?
                </H4> 

                <Grid className="view-limit lg:px-8 mt-3 mb-10" columns="4" gutter="" rows="fit-vertically">
                    {stepCard('security-fingerprint', 'Recupera password', 'SICUREZZA')}
                </Grid>
      
            </div>
            <Hr/>

            <H4 className="text-center mt-14">
                Non hai trovato quello che cerchi?
            </H4>
            <Detail className="text-center vertical-padding-xxsmall">
                Contattaci, ti aiuteremo.
            </Detail>
            <Grid columns="3">
                <div></div>
                <ContactForm handleLoader={handleLoader}/>
                <div></div>
            </Grid>
            
        </div>
    )
}

export default Help

import  { ReactElement } from 'react'
import { IBook } from '../interfaces/IBook';
import Book from './Book';

interface Props {
    data: IBook[]
}

export default function RenderBooks({ data }: Props): ReactElement {
    if (data.length > 0) {
        const books: ReactElement[] = data.map((v, i) => <Book key={i} data={v}/>);
        return <>{books}</>;
    } else {
        return <></>;
    }
}

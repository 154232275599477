import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as UserService from '../services/UserServices';
import {
    Banner,
    Button,
    Detail,
    H3,
    Grid,
    Form,
    InputPassword,
} from '@maggioli-design-system/react'
import { ISubmit } from '../interfaces/ISubmit';

export default function ResetPassword(): ReactElement {

    const { code } = useParams();

    const [submit, setSubmit] = useState<ISubmit>({ text: 'Conferma', status: '' });
    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');

    const submitForm = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password !== password2 || password.length < 6 || password2.length < 6) {
            alert('Le password devono coincidere (lunghezza min. 6 caratteri)');
            return;
        }

        const res = await UserService.resetPassword(password, code);
        const data = await res.json();
        if (!data.status) {
            setSubmit({ text: data.message, status: 'error' });
            window.setTimeout(() => setSubmit({ text : 'Conferma', status : '' }), 3000);
            return;
        }
        setSubmit({ text: 'Password cambiata con successo!', status: 'success' });
        window.setTimeout(() => { window.location.href = '/login' }, 2000);
    }

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <H3 className="text-center vertical-padding-xxsmall">Nuova password</H3>
            <Grid columns="3">
                <div></div>
                <Form onSubmit={(e: ChangeEvent<HTMLFormElement>) => submitForm(e)} 
                        className="background-color-adjust-tone-17 padding-small border-radius-small">
                    
                    <InputPassword value={password} label="Password" placeholder="Password..." 
                            onChange={(e: ChangeEvent<HTMLFormElement>) => setPassword(e.target.value)}/>

                    <InputPassword value={password2} label="Ripeti password" placeholder="Ripeti password..." 
                            onChange={(e: ChangeEvent<HTMLFormElement>) => setPassword2(e.target.value)}/>

                    { submit.status === '' ? 
                    <div><Button variant={submit.status} type="submit">{submit.text}</Button></div>
                    : 
                    <Banner status={submit.status} className="border-radius-normal">
                        <Detail>
                            {submit.text}
                        </Detail>
                    </Banner> }
                </Form> 
            </Grid>
        </div>
    )
}

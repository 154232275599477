import React, { ReactElement, useState } from "react";
import { ActionsBar, ActionsBarItems, Grid, H4, Header, HeaderLogo, HeaderMenu, Button, Detail, Modal } from "@maggioli-design-system/react";
import { scrollTop, logout, useWindowSize } from "../utils/Functions";
import { Link } from "react-router-dom";
import { getCookie } from "../utils/Macros";
import { IUserRole } from "../interfaces/IUser";

const SHOP_URL: string = "https://www.maggiolieditore.it/?utm_source=approfondimenti";

interface IProps {
    user: IUserRole;
}

const HeaderApprofondimenti = ({ user }: IProps): ReactElement => {
    const [visible, setVisible] = useState<boolean>(false);
    const [confirmLogout, setConfirmLogout] = useState<boolean>(false);

    const loggedUser: string = getCookie("loggedUser");
    const isLoggedUser: boolean = loggedUser !== null;
    const loggedUserMail: string = loggedUser !== null ? JSON.parse(loggedUser).email : "";

    const windowWidth = useWindowSize();

    const scrollAndCloseModal = () => {
        scrollTop();
        setVisible(false);
    };

    const modalMenu = () => (
        <Grid gutter="xsmall" className="padding-xsmall background-color-adjust-tone-19 mt-24">
            <Link to="/pages/help" onClick={scrollAndCloseModal}>
                <Button variant="text" className="text-adjust-tone-04 px-2" horizontalPadding="none">
                    Aiuto
                </Button>
            </Link>
            <a href={SHOP_URL} target="_blank" rel="noreferrer">
                <Button variant="text" className="text-adjust-tone-04 px-2" horizontalPadding="none">
                    Shop
                </Button>
            </a>
            {!isLoggedUser ? (
                <Link to="/login">
                    <Button width="fill" variant="primary-outline" onClick={scrollAndCloseModal}>
                        Accedi
                    </Button>
                </Link>
            ) : (
                <Link to="/myBooks/page/1">
                    <Button onClick={scrollAndCloseModal} width="fill" icon="document-book" variant="primary">
                        I tuoi libri
                    </Button>
                </Link>
            )}

            {user.isWorker && (
                <Button variant="primary-outline" icon="dashboard" onClick={() => window.open("/admin/", "_blank")}>
                    Gestisci contenuti
                </Button>
            )}

            {isLoggedUser && (
                <Link to="/account">
                    <Button width="fill" variant="primary-outline" horizontalPadding="none" icon="user" onClick={scrollAndCloseModal}>
                        Il mio profilo
                    </Button>
                </Link>
            )}

            {!isLoggedUser ? (
                <Link to="/users/register">
                    <Button width="fill" variant="success" onClick={scrollAndCloseModal}>
                        Registrati
                    </Button>
                </Link>
            ) : (
                <Button
                    icon="action-logout"
                    variant="error-outline"
                    onClick={(e) => {
                        e.stopPropagation();
                        setConfirmLogout(!confirmLogout);
                    }}
                    horizontalPadding="none"
                    title="Esci"
                >
                    Esci
                </Button>
            )}
        </Grid>
    );

    const wideMenu = () => (
        <HeaderMenu className="overflow-visible lg:pr-8" onClick={() => setVisible(!visible)}>
            <Link to="/pages/help" onClick={scrollTop}>
                <Button variant="text" className="text-adjust-tone-04 px-2" horizontalPadding="none">
                    Aiuto
                </Button>
            </Link>
            <a href={SHOP_URL} target="_blank" rel="noreferrer">
                <Button variant="text" className="text-adjust-tone-04 px-2" horizontalPadding="none">
                    Shop
                </Button>
            </a>

            {!isLoggedUser ? (
                <Link to="/login">
                    <Button variant="primary-outline">Accedi</Button>
                </Link>
            ) : (
                <Link to="/myBooks/page/1">
                    <Button onClick={scrollTop} icon="document-book" variant="primary">
                        I tuoi libri
                    </Button>
                </Link>
            )}

            {user.isWorker && (
                <Button variant="primary-outline" onClick={() => window.open("/admin/", "_blank")}>
                    Gestisci contenuti
                </Button>
            )}

            {isLoggedUser && (
                <Link to="/account">
                    <Button variant="primary-outline" horizontalPadding="none" icon="user" onClick={scrollTop} />
                </Link>
            )}

            {!isLoggedUser ? (
                <Link to="/users/register">
                    <Button variant="success" onClick={() => {}}>
                        Registrati
                    </Button>
                </Link>
            ) : (
                <Button
                    icon="action-logout"
                    variant="error-outline"
                    onClick={(e) => {
                        e.stopPropagation();
                        setConfirmLogout(!confirmLogout);
                    }}
                    horizontalPadding="none"
                    title="Esci"
                ></Button>
            )}
        </HeaderMenu>
    );

    return (
        <div>
            {confirmLogout && <div className="background-color-adjust-tone-10 opacity-60 z-10 fixed h-full w-full" />}

            <ActionsBar className="z-20" position="right" boxShadow="box" notVisible={!confirmLogout}>
                <Grid gutter="none">
                    <H4>Sei sicuro di voler uscire?</H4>
                    <Detail className="italic">{loggedUserMail}</Detail>
                </Grid>
                <ActionsBarItems>
                    <Button variant="primary-outline" icon="crud-cancel" onClick={() => setConfirmLogout(false)}>
                        Annulla
                    </Button>
                    <Button variant="error" icon="action-logout" onClick={() => logout()}>
                        Esci
                    </Button>
                </ActionsBarItems>
            </ActionsBar>

            <Header lastChild="to-right" className="z-20" onClick={() => setConfirmLogout(false)}>
                <Link className="lg:pl-8" to={"/"} onClick={scrollTop}>
                    <HeaderLogo src={`${process.env.PUBLIC_URL}/assets/images/logo-contenuti-aggiuntivi.svg`} />
                </Link>
                <Grid gutter="none">
                    <H4>Approfondimenti</H4>
                    <Detail>Libri Maggioli Editore</Detail>
                </Grid>
                {wideMenu()}
            </Header>
            {windowWidth < 1024 && (
                <Modal visible={visible} onCancel={() => setVisible(false)} position="right" footer={false} contentOnly>
                    {modalMenu()}
                </Modal>
            )}
        </div>
    );
};

export default HeaderApprofondimenti;

export const root: string = process.env.NODE_ENV === 'production' ?
            'https://approfondimenti.maggiolicloud.it/' :
            'https://approfondimenti.maggiolicloud.it/';

export const frontendURL: string = process.env.NODE_ENV === 'production' ?
            'https://approfondimenti.maggiolicloud.it/' :
            'http://localhost:3000/';

const baseURL: string = root + 'core/auth/api/v2/';

export const privacyURL: string = "https://privacy.maggiolicloud.it";
export const privacyToken: string =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJfIiwiYXVkIjoxLCJpYXQiOjE1OTI4MzQyMjIsIm5iZiI6MTU5MjgzNDIyMiwiZXhwIjoxOTA4MTk0MjIyLCJkYXRhIjp7ImlkIjo4LCJzaXRlX2lkIjoxMX19.cRQY2djrsfIFM-F-9E6AKFQ_mnZdblsxmiMtbFGKqFg";
export const guidaNormativaToken: string =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJwcml2YWN5Lm1hZ2dpb2xpY2xvdWQuaXQiLCJhdWQiOjEsImlhdCI6MTYxMzM4NDg1NCwibmJmIjoxNjEzMzg0ODU0LCJleHAiOjE5Mjg3NDQ4NTQsImRhdGEiOnsiaWQiOjU5LCJzaXRlX2lkIjo4NH19.Kr1v3CnOaGj2xYqZlQP2YlD3P9vnbS8guOflMFVuvFg";
export const adminURL: string = root;
export const deleteURL: string = baseURL + 'delete/';
export const getURL: string = baseURL + 'get/';
export const postURL: string = baseURL + 'post/';
export const putURL: string = baseURL + 'put/';
export const uploads: string = root + 'uploads/';

export const emailParams = {
    host: 'smtp.sendgrid.net',
    port: 465,
    user: 'apikey',
    password: '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',
}

// var myHeaders = new Headers();
// myHeaders.append("Authorization", "Basic c3RlZmFuby5wYXNjYXppQG1hZ2dpb2xpLml0Ok00cmMwbmQxcjBuZDNsbDAh");

// var requestOptions = {
//   method: 'GET',
//   headers: myHeaders,
//   redirect: 'follow'
// };

export function getCookie(name: string) {
    let nameEQ: string = name + "=";
    let ca: string[] = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c: string = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length,c.length)
        };
    }
    return null;
}

export function setCookie(name: string, value: string, days: number = 360) {
    let expires: string = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const reda: any = {
    getTree : "https://sisred.maggiolicloud.it/api/voce-albero/",
    getDocumens : "https://sisred.maggiolicloud.it/api/document/_search?categorie=",
    getProvvedimento : "https://sisred.maggiolicloud.it/api/provvedimento/",
    download : "https://sisred.maggiolicloud.it/api/document/"
}

export const SUBSCRIBER_ROLE: string = '1';
export const GLOBAL_ROLE: string     = '5';
export const ILIBRO_ROLE: string     = '7';
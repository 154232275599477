import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Banner, Button, Detail, Grid, Form, H3, InputText, Select, Radio } from "@maggioli-design-system/react";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import * as Validator from "../utils/Validator";
import * as BookService from "../services/BookServices";
import * as Macros from "../utils/Macros";
import * as Privacy from "../services/Privacy";
import { ISubmit } from "../interfaces/ISubmit";
import { IForm } from "../interfaces/IForm";

interface Props {
    handleLoader: IHandleLoader;
}

const INIT_TEXT: string = "Invia";
const INIT_STAT: string = "";

export default function GuidaNormativa({ handleLoader }: Props): ReactElement {
    const [form, setForm] = useState<IForm>(null);
    const [commerciale, setCommerciale] = useState<boolean>(null);
    const [provincia, setProvincia] = useState<string>("");
    const [job, setJob] = useState<string>("");
    const [submit, setSubmit] = useState<ISubmit>({ text: INIT_TEXT, status: INIT_STAT });

    useEffect(() => {
        getForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getForm = async () => {
        handleLoader.setLoading(true);
        const res = await Privacy.getForms(Macros.guidaNormativaToken);
        const data = await res.json();
        if (data.form === undefined || data.form.length === 0) {
            return;
        }
        setForm(data.form.filter((elem: IForm) => elem.nome === "guida-normativa").pop());
        handleLoader.setLoading(false);
    };

    const formFields = () => {
        return form.fields
            .filter((elem) => elem.type !== "hidden" && elem.input === "input")
            .map((elem, index) => <InputText key={index} label={elem.label} name={elem.name} placeholder={elem.placeholder} />);
    };

    const hiddenFields = () => {
        return form.fields
            .filter((elem) => elem.type === "hidden")
            .map((elem, index) => <input key={index} type="hidden" name={elem.name} value={elem.options.value} />);
    };

    const privacyFields = (): JSX.Element[] => {
        return form.privacy.map((elem, index: number) => (
            <div key={index}>
                <Detail className="privacy-consent" htmlTag={"div"}>
                    <p dangerouslySetInnerHTML={{ __html: elem.description }} />
                    {elem.fields.map((item, i: number) => (
                        <Radio
                            key={i}
                            name={item.name}
                            value={item.options.value}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => privacyHandler(e.target.value, item.name)}
                        >
                            {item.options.text}
                        </Radio>
                    ))}
                </Detail>
            </div>
        ));
    };

    const selectFields = () => {
        return form.fields
            .filter((elem) => elem.input === "select")
            .map((elem, index) => (
                <Select
                    key={index}
                    label={elem.label}
                    name={elem.name}
                    data={elem.options.map((elem) => elem.value)}
                    value={elem.name === "provincia" ? provincia : job}
                    onChange={(e) => selectHandler(e.target.value, elem.name)}
                />
            ));
    };

    const privacyHandler = (value: string, privacyName: string) => {
        switch (privacyName) {
            case "commerciale":
                setCommerciale(value === "1");
                break;
            default:
                break;
        }
    };

    const selectHandler = (value: string, fieldName: string) => {
        switch (fieldName) {
            case "jobs":
                setJob(value);
                break;
            case "provincia":
                setProvincia(value);
                break;
            default:
                break;
        }
    };

    const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (submit.status === "success") {
            return;
        }

        if (commerciale === null) {
            alert("Tutti i campi sono obbligatori.");
            return;
        }

        const resIP = await Privacy.getMyIP();
        if (!resIP.ok) {
            setSubmit({ text: "Errore nel recupero dell'IP", status: "error" });
            return;
        }
        const dataIP = await resIP.json();

        const formData = new FormData(e.target);
        const data = {};
        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }
        
        if (!Validator.nameSurnameValidation(data["nome"], data["cognome"])) {
            alert("Nome/cognome non valido. \nCaratteri ammessi: lettere e spazi.");
            return;
        }

        data["token"] = Macros.guidaNormativaToken;
        data["ip"] = dataIP.ip;

        if (!Validator.emailValidation(data["email"])) {
            alert("Email non valida.");
            return;
        }

        const resDelete = await BookService.deleteCodeGuidaNormativa(data["codice"]);
        const dataDelete = await resDelete.json();
        if (!dataDelete.status) {
            alert(dataDelete.message);
            return;
        }

        const payload = {
            email: "segreteria@guidaentilocali.it", // email amministrazione segreteria@guidaentilocali.it
            // from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "Approfondimenti Maggiolicloud", //nome sito
            // replyTo: formData.get("email"), //email inserita dall'utente
            subject: "Attivazione gratuita www.guidaentilocali.it per Guida Normativa 2024",
            description: `<h1>Maggioli Editore - Guida Normativa 2024</h1>
                        <h2>Richiesta attivazione gratuita a www.guidaentilocali.it per acquisto Guida Normativa 2024</h2>
                        <p>Attivare accesso gratuito a www.guidaentilocali.it al seguente utente:</p>
                        <p>${formData.get("email")}</p>
                        <p>In caso di problemi, pu&ograve; contattare il nostro Servizio Clienti telefonando al numero 0541-628200 o inviando una e-mail all'indirizzo <a href="mailto:clienti.editore@maggioli.it">clienti.editore@maggioli.it</a>.</p>
                        <p>Cordiali saluti<br>La redazione</p>`,
            // responseParams: {
            //     to: formData.get("email"), //email inserita dall'utente
            //     message: "Grazie per averci contattato. A breve riceverai una risposta da parte della segreteria di GuidaEntiLocali.",
            // },
            // params: {
            //     ...Macros.emailParams,
            // },
        };

        handleLoader.setLoading(true);
        const resPrivacy = await Privacy.saveConsentInPrivacy(data);
        if (!resPrivacy.ok) {
            return;
        }

        const resMail = await Privacy.sendEmail(payload);
        if (resMail.ok) {
            handleLoader.setLoading(false);
            setSubmit({ text: "Richiesta inviata con successo!", status: "success" });
            sessionStorage.setItem("thankYouType", "guida");
            window.setTimeout(() => window.location.replace("/thank-you"), 2000);
        }
    };

    const linkGuidaEnti: ReactElement = (
        <a className="link-guida-norma" target="_blank" rel="noreferrer" href="https://www.guidaentilocali.it/">
            guidaentilocali.it
        </a>
    );

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <Grid columns="2" className="view-limit grid-cols-4 mt-10">
                <Grid columns="1" gutter="xsmall" rows="fit-vertically" className="col-span-2">
                    <H3 className="text-left vertical-padding-xxsmall">Benvenuto nella pagina di registrazione alla Guida Normativa 2024</H3>

                    <Detail className="vertical-padding-xxsmall">
                        Inserendo i tuoi dati potrai accedere <strong>gratuitamente</strong> al portale&nbsp;
                        {linkGuidaEnti}
                    </Detail>

                    <Detail className="vertical-padding-xxsmall">
                        Dopo aver compilato regolarmente il form di registrazione, riceverai, entro pochi giorni, una e-mail dalla segreteria di{" "}
                        {linkGuidaEnti}, contenente username e password per accedere al sito.
                    </Detail>

                    {form !== null && (
                        <Grid gutter="small">
                            <Form className="mt-4" onSubmit={(e: ChangeEvent<HTMLFormElement>) => onSubmit(e)}>
                                <Grid columns="2">
                                    {formFields()}

                                    {selectFields()}
                                </Grid>

                                {hiddenFields()}

                                <input name="trattamento" value="1" type="hidden" />
                                <input name="servizio" value={form.servizio} type="hidden" />

                                {/* Consenso informato */}
                                <Detail htmlTag={"div"}>
                                    <strong>
                                        <p className="privacy-consent" dangerouslySetInnerHTML={{ __html: form.consenso_informato }} />
                                    </strong>
                                </Detail>

                                {privacyFields()}

                                <Detail>
                                    *Si precisa che cliccando su “Invia” si autorizzerà Maggioli S.p.A. a comunicare i propri dati a
                                    guidaentilocali.it (di Studio Narducci), per l’erogazione del servizio.
                                </Detail>
                                <div>
                                    <Button type="submit">Invia</Button>
                                </div>
                                {submit.status !== "" && (
                                    <Banner status={submit.status} className="border-radius-small">
                                        <Detail>{submit.text}</Detail>
                                    </Banner>
                                )}
                            </Form>
                        </Grid>
                    )}
                </Grid>
                <Grid className="col-span-2">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/guida-normativa.png`} className="img-fluid" alt="logo guida normativa" />
                </Grid>
            </Grid>
        </div>
    );
}

export default class Singleton {

    static token = null;
  
    static getInstance(token = null) {
        if( Singleton.token === null ){
            Singleton.token = token !== null ? token : Singleton.token
        }
        return this.token
    }
}
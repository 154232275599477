import React, { ReactElement } from "react";
import HeaderApprofondimenti from "./Header";
import Footer from "./Footer";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import { IUserRole } from "../interfaces/IUser";

interface Props {
    children: JSX.Element;
    handleLoader: IHandleLoader;
    user: IUserRole;
}

export default function PageTemplate({ children, handleLoader, user }: Props): ReactElement {
    return (
        <div>
            <HeaderApprofondimenti user={user} />
            {children}
            <Footer loader={handleLoader.loading} />
        </div>
    );
}

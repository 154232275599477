import React, { ChangeEvent, ReactElement, useState } from 'react'
import * as UserService from '../services/UserServices';
import * as Privacy from '../services/Privacy';
import * as Validator from '../utils/Validator';
import * as Macros from '../utils/Macros';
import {
    Banner,
    Button,
    Detail,
    H3,
    Grid,
    Form,
    InputText,
} from '@maggioli-design-system/react'
import { IHandleLoader } from '../interfaces/IHandleLoader';

interface ISubmit {
    text: string,
    status: string
}

const INIT_TEXT: string = 'Recupera la password';
const INIT_STAT: string = '';

interface Props {
    handleLoader: IHandleLoader
}

export default function RecoverPassword({ handleLoader }: Props): ReactElement {

    const [submit, setSubmit] = useState<ISubmit>({ text: INIT_TEXT, status: INIT_STAT });
    const [email, setEmail] = useState<string>('');

    const submitForm = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (submit.status === 'success' || submit.status === 'error') {
            return;
        }

        handleLoader.setLoading(true);
        const res = await UserService.passwordRecovery(email);
        const data = await res.json();
        if (!data.status) {
            setSubmit({ text: data.message, status: 'error' });
            window.setTimeout(() => setSubmit({ text : INIT_TEXT, status : INIT_STAT }), 2000);
            handleLoader.setLoading(false);
            return;
        }

        if (!Validator.emailValidation(email)) {
            alert('Email non valida.');
            handleLoader.setLoading(false);
            return;
        }

        const payload = {
            email: email, // email amministrazione
            // from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "Approfondimenti Maggiolicloud", //nome sito
            // replyTo: "no-reply-siti-web@maggiolieditore.it", //email inserita dall'utente
            subject: "Recupero password Approfondimenti Libri Maggioli",
            description: `<p>Salve, </p><p>se ha ricevuto questa email è perchè qualcuno
                        ha richiesto il cambio password. <br> Se non si è sicuri di quello che
                        si sta facendo o se non si ha richiesto nessuna azione, eliminate questa
                        stessa email.</p><p>In alternativa per procedere al cambio password,
                        cliccare sul link sottostante:
                        <br><a href="${Macros.frontendURL}resetPassword/${data.data}">Clicca qui</a></p>
                        <p>Nota: il link è valido solo nella giornata odierna.</p>
                        <p>Distinti saluti<br>Lo staff di Maggioli Editore</p>`,
            // params: {
            //     ...Macros.emailParams,
            // },
        };

        const resPrivacy = await Privacy.sendEmail(payload);
        handleLoader.setLoading(false);
        if (resPrivacy.ok) {
            setSubmit({ text: 'Email inviata con successo, controlla la tua posta in arrivo.', status: 'success' });
        }
    }

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <H3 className="text-center vertical-padding-xxsmall">Recupera la password</H3>
            <Grid columns="3">
                <div></div>
                <Form onSubmit={(e: ChangeEvent<HTMLFormElement>) => submitForm(e)}
                        className="background-color-adjust-tone-17 padding-small border-radius-small">

                    <Detail htmlTag="div">
                        Nel caso tu abbia dimenticato la tua password, potrai impostarne una nuova. <br/>
                        Una volta avviata la procedura di recupero password, riceverai una email con un link di accesso alla pagina di
                        reimpostazione della password, accessibile solo tramite link generato nella email che ti abbiamo inviato.
                        <p><b>Nota:</b> il link è valido solo nella giornata odierna.</p>
                    </Detail>

                    <InputText value={email} name='email' label='Email'
                            onChange={(e: ChangeEvent<HTMLFormElement>) => setEmail(e.target.value)}
                            placeholder='Inserisci la tua email di registrazione...'/>

                    { submit.status === '' ?
                    <div><Button variant={submit.status} type="submit">{submit.text}</Button></div>
                    :
                    <Banner status={submit.status}>
                        <Detail>
                            { submit.text }
                        </Detail>
                    </Banner> }
                </Form>
            </Grid>
        </div>
    )
}

import { Link } from "react-router-dom";
import { Caption, Detail, Grid, HeaderLogo, Image, H5, Row } from "@maggioli-design-system/react";
import { scrollTop } from "../utils/Functions";
import { TailSpin } from "react-loader-spinner";

interface Props {
    loader: boolean;
}

const Footer = ({ loader }: Props): JSX.Element => {
    const currentYear: number = new Date().getFullYear();
    const privacyURL: string = "https://privacy.maggiolicloud.it/privacy/approfondimentimaggiolicloudit";

    const loaderComponent = (): JSX.Element => (
        <>
            <div className="bg-loader-appro" />
            <div className="loader-appro">
                <TailSpin color="#0041B9" />
            </div>
        </>
    );

    return (
        <footer className="background-color-adjust-tone">
            {loader && loaderComponent()}
            <Grid className="view-limit py-16 px-8" columns="3">
                <div>
                    <Grid gutter="xsmall">
                        <HeaderLogo src={`${process.env.PUBLIC_URL}/assets/images/logo-contenuti-aggiuntivi.svg`} />
                        <Caption className="mt-4">
                            <strong>
                                Approfondimenti è un servizio del <br />
                                Gruppo Maggioli
                            </strong>
                        </Caption>
                        <Caption>
                            Via del Carpino, 8<br />
                            47822 Santarcangelo di Romagna (RN) Italia
                        </Caption>
                    </Grid>
                </div>
                <div>
                    <Grid gutter="xsmall">
                        <H5>Servizio clienti</H5>
                        <Caption>
                            Disponibile dal Lunedì al Venerdì
                            <br />
                            Dalle 08:30 alle 17:30
                        </Caption>
                        <Caption>
                            Tel. +39 0541 628200
                            <br />
                        </Caption>
                        <Detail>
                            <Link to={"/contact"} onClick={scrollTop}>
                                Contattaci
                            </Link>
                        </Detail>
                    </Grid>
                </div>
                <div>
                    <Grid gutter="xsmall">
                        <Detail>
                            <a href="/informativa-sui-cookies">Cookie policy</a>
                        </Detail>
                        <Detail>
                            <a href={privacyURL} target="_blank" rel="noreferrer">
                                Privacy policy
                            </a>
                        </Detail>
                        <Image className="w-16" src={`${process.env.PUBLIC_URL}/assets/images/logo-gruppo-maggioli.svg`} />
                    </Grid>
                </div>
            </Grid>
            <div className="background-color-brand-maggioli-04 color-brand-maggioli-20">
                <Row className="view-limit p-4">
                    <Caption className="m-auto">
                        Copyright 2003-{currentYear} Maggioli Spa - P.Iva 02066400405 - Iscritta al R.E.A. di Rimini al n. 219107
                    </Caption>
                </Row>
            </div>
        </footer>
    );
};

export default Footer;

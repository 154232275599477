import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as BookService from "../services/BookServices";
import * as AttachService from "../services/AttachmentServices";
import { Detail, Grid, Paginator, Banner, Button, H2, H4, Hr, Icon, InputText, Row, Select, UList, UListItem } from "@maggioli-design-system/react";
import { IBook, IBookMeta } from "../interfaces/IBook";
import { IAttach } from "../interfaces/IAttach";
import { IArea } from "../interfaces/IArea";
import { Link } from "react-router-dom";
import ILibro from "../components/ILibro";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import Redazionale from "../components/Redazionale";
import { scrollTop, useWindowSize } from "../utils/Functions";
import { passwordRecovery } from "../services/UserServices";
import Informativa from "../components/Informativa";
import DownloadAttach from "../components/DownloadAttach";
import SideBook from "../components/SideBook";
import { getCookie, ILIBRO_ROLE } from "../utils/Macros";
import { IUserRole } from "../interfaces/IUser";
import { getRedaBook } from "../services/Redazionale";

interface Props {
    handleLoader: IHandleLoader;
    user: IUserRole;
}

interface ITree {
    id?: number;
    voce?: string;
}

interface IRedaBook {
    formati?: string[];
}

export default function BookPage({ handleLoader, user }: Props): ReactElement {
    const { id } = useParams();
    const history = useNavigate();
    const windowSize = useWindowSize();
    const loggedUser: string = getCookie("loggedUser");
    const loggedUserID: string = loggedUser !== null ? JSON.parse(loggedUser).id : "";
    const loggedUserEmail: string = loggedUser !== null ? JSON.parse(loggedUser).email : "";
    const RESULTS: number = 10;
    const quizURL: string = "https://simulatorequizconcorsi.it";

    const [book, setBook] = useState<IBook>(null);
    const [redaBook, setRedaBook] = useState<IRedaBook>(null);
    const [attach, setAttach] = useState<IAttach[]>([]);
    const [filteredAttach, setFilterAttach] = useState<IAttach[]>([]);
    const [areas, setAreas] = useState<IArea[]>([]);
    const [informativa, setInformativa] = useState<boolean>(false);
    const [showIlibro, setShowIlibro] = useState<boolean>(false);
    const [quizID, setQuizID] = useState<string>("");

    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selArea, setSelArea] = useState<string>("Tutte");

    /**
     * start edit from redazionale
     */
    const [idBancadati, setIdBancadati] = useState<string | number>();
    const [idLegacy, setIdLegacy] = useState<string | number>();
    const [tree, setTree] = useState<ITree>();
    const [redaShow, setRedaShow] = useState<boolean>(false);
    const [isAuthored, setIsAuthored] = useState<boolean>(false);

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleLoader.setLoading(true);

        const data = {
            "book_id": id,
            "user_id": loggedUserID,
        };
        BookService.saveLog(data);
        loadBook();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!book) {
            return;
        }
        if (idBancadati) {
            BookService.getTree(idBancadati, (result: any) => {
                if (result === 404) {
                    console.log("error");
                } else {
                    setTree(result);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idBancadati, book, idLegacy]);

    const loadBook = async () => {
        let auth: boolean = user.isWorker;
        if (!auth) {
            const res = await BookService.getRedeemedBooks(loggedUserID);
            const data = await res.json();
            auth = data.status && data.data.map((b: IBook) => b.id).includes(id);
        }
        setIsAuthored(auth);

        const resBook = await BookService.getBookByID(id);
        const dataBook = await resBook.json();
        if (!dataBook.status) {
            handleLoader.setLoading(false);
            return;
        }
        getIdRedazionale(dataBook.data);
        getIdLegacy(dataBook.data);
        setBook(dataBook.data);
        loadNewQuizLink(dataBook.data);

        const redaBookCheck = await getRedaBook(dataBook.data.isbn);
        if (redaBookCheck) {
            setRedaBook(redaBookCheck);
        }

        if (!auth) {
            handleLoader.setLoading(false);
            return;
        }

        const resAttach = await AttachService.getAttachmentsByBook(id);
        const dataAttach = await resAttach.json();
        handleLoader.setLoading(false);
        if (!dataAttach.status) {
            return;
        }

        setAttach(dataAttach.data);
        setFilterAttach(dataAttach.data);
        setAreas(dataAttach.areas);
        setPages(calculatePages(dataAttach.data.length));
    };

    // Ricerca allegati
    const filterAttach = (text: string) => {
        setSelArea("Tutte");
        if (text.length > 2) {
            const data: IAttach[] = attach.filter(
                (elem) =>
                    elem.title.toLowerCase().includes(text.toLowerCase()) ||
                    elem.guid.toLowerCase().includes(text.toLowerCase()) ||
                    elem.description.toLowerCase().includes(text.toLowerCase()) ||
                    elem.meta
                        .map((m) => m.meta_value)
                        .join(" ")
                        .toLowerCase()
                        .includes(text.toLowerCase()),
            );
            updateAttach(data);
        } else {
            updateAttach(attach);
        }
    };

    // Filtro allegati per area
    const selectArea = (newArea: string) => {
        setSelArea(newArea);
        if (newArea === "Tutte") {
            updateAttach(attach);
        } else {
            const area: IArea = areas.filter((elem) => elem.title === newArea).pop();
            const data: IAttach[] = attach.filter((elem) => elem.area_id === area.id);
            updateAttach(data);
        }
    };

    // Aggiorna gli allegati mostrati e il paginatore
    const updateAttach = (data: IAttach[]) => {
        setFilterAttach(data);
        setPages(calculatePages(data.length));
        setCurrentPage(1);
    };

    // Ritorna il n. di pagine a seconda del numero di allegati
    const calculatePages = (numAttach: number): number => {
        const numPages: number = Math.floor(numAttach / RESULTS);
        return numAttach % RESULTS !== 0 ? numPages + 1 : numPages;
    };

    // Funzioni paginatore
    const onClickNextPage = () => {
        currentPage < pages && setCurrentPage(currentPage + 1);
    };
    const onClickPrevPage = () => {
        currentPage > 1 && setCurrentPage(currentPage - 1);
    };
    const onClickPage = (newPage: number) => {
        setCurrentPage(newPage);
    };

    // Prende l'ide redazionale (se c'è) a partire dal libro
    const getIdRedazionale: Function = (book: IBook) => {
        const idRedazionale: IBookMeta =
            book !== null ? book.meta.filter((elem) => elem.meta_key.toLowerCase().includes("id_bancadati")).pop() : null;
        idRedazionale && setIdBancadati(idRedazionale.meta_value);
        return idRedazionale;
    };

    // Prende l'ide redazionale (se c'è) a partire dal libro
    const getIdLegacy: Function = (book: IBook) => {
        const idLegacy: IBookMeta = book !== null ? book.meta.filter((elem) => elem.meta_key.toLowerCase().includes("id_legacy")).pop() : null;
        idLegacy && setIdLegacy(idLegacy.meta_value);
        return idLegacy;
    };

    // Stile informativa diritto d'autore
    const informativaStyle = {
        height: informativa ? "100%" : "0px",
        opacity: informativa ? "100%" : "0%",
        transition: "all 0.3s ease-out",
    };

    const informativaButton = () => {
        return (
            <div>
                <Button
                    variant="primary-outline"
                    onClick={() => setInformativa(!informativa)}
                    icon={informativa ? "navigation-hide" : "navigation-show"}
                >
                    Informativa sul diritto d'autore
                </Button>
            </div>
        );
    };

    const onClickLegacyLink = () => {
        if (book.meta.map((m) => m.meta_key).includes("link_legacy")) {
            window.open(
                book.meta
                    .filter((m) => m.meta_key === "link_legacy")
                    .pop()
                    .meta_value.toString(),
                "_blank",
            );
        } else {
            window.open(`https://approfondimenti.maggiolicloud.it/uploads/legacy/vol_${book.isbn.slice(3)}/index.htm`, "_blank");
        }
    };

    const loadNewQuizLink = async (data: IBook) => {
        const linkModuli: IBookMeta = data !== null ? data.meta.filter((elem) => elem.meta_key.toLowerCase().includes("simulatore")).pop() : null;
        if (linkModuli) {
            const formData = new FormData();
            formData.append("old_link", linkModuli.meta_value.toString());
            const res = await fetch(`${quizURL}/core/api/tests/parse-old/`, {
                method: "POST",
                body: JSON.stringify({ old_link: linkModuli.meta_value }),
                headers: { "Content-Type": "application/json" },
            });
            if (!res.ok) {
                return;
            }
            const data = await res.json();
            if (data.status) {
                setQuizID(data.data);
            }
        }
    };

    const onClickQuiz = async () => {
        const windowReference = window.open();
        const link: string = book.meta
            .filter((e) => e.meta_key === "simulatore")
            .pop()
            .meta_value.toString();
        const pattern = link.split("moduli.maggioli.it/quiz/training/setup/");

        const res = await passwordRecovery(loggedUserEmail);
        const data = await res.json();
        if (!data.status) {
            return;
        }

        if (pattern.length !== 2) {
            windowReference.location.href = `${link}/${data.data}`;
        } else {
            const quizRedirectLink: string = `${quizURL}` + (quizID !== "" ? `/quiz/${quizID}` : "");
            windowReference.location.href = `${quizRedirectLink}/${data.data}`;
        }
    };

    const onClickIlibro = () => {
        setShowIlibro(true);
    };

    const ilibroButton = () => {
        if (redaBook === null) {
            return;
        }
        const isIlibro: boolean = redaBook.formati && redaBook.formati.includes("iLibro");
        return (
            <div>
                <Button onClick={() => onClickIlibro()} icon={isIlibro ? "logo-ilibro" : ""}>
                    Consulta {isIlibro ? "iLibro" : "il libro"}
                </Button>
            </div>
        );
    };
    const simulatoreButton = () => (
        <div>
            <Button onClick={() => onClickQuiz()}>Link simulatore</Button>
        </div>
    );

    const showLinkSimulatore: boolean = book !== null && book.meta.filter((e) => e.meta_key === "simulatore").length > 0;
    const showILibro: boolean = book !== null && book.meta.filter((elem) => elem.meta_key.includes("external")).length > 0;
    const start: number = (currentPage - 1) * RESULTS;
    const end: number = start + RESULTS;

    if (book === null || book.published === "0") {
        return (
            <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
                <div className="view-limit pt-8 pb-0 lg:pb-8">
                    <Grid>
                        <Row>
                            <Icon name="status-warning"> </Icon>
                            <H2>{book === null ? "Volume non trovato" : "Volume non ancora pubblicato"}</H2>
                        </Row>
                        <div>
                            <Button variant="primary-outline" icon="action-back" onClick={() => history(-1)}>
                                Torna indietro
                            </Button>
                        </div>
                    </Grid>
                </div>
            </div>
        );
    }

    const buttons = [
        informativaButton(),
        showILibro && isAuthored && ilibroButton(),
        showLinkSimulatore && isAuthored && user.role !== ILIBRO_ROLE && simulatoreButton(),
    ];

    return (
        <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
            <div className="view-limit pt-8 pb-0 lg:pb-8">
                <Grid className="lg:grid-cols-5" gutter="xlarge">
                    <SideBook book={book} />

                    <Grid rows="fit-vertically" className="lg:col-span-4" gutter="small">
                        {/* Titolo volume */}
                        <H2>{book.title}</H2>

                        {windowSize > 768 ? <Row>{buttons}</Row> : <Grid>{buttons}</Grid>}

                        <div style={informativaStyle}>{informativa && <Informativa showLine={book.description !== ""} />}</div>

                        {/* Descrizione del volume */}
                        {book.description !== "" && book.description !== null && (
                            <Detail>
                                <strong>Descrizione: </strong>
                                <p dangerouslySetInnerHTML={{ __html: book.description }} />
                            </Detail>
                        )}

                        <Hr spacing="xsmall" />

                        {/* Redazionale se c'è */}
                        {tree !== undefined && isAuthored && user.role !== ILIBRO_ROLE && (
                            <div>
                                <Grid className="gap-2">
                                    <H4 className="mb-4">Consulta i documenti pubblicati</H4>
                                    <UList iconSize="normal" text="text-secondary text-secondary--detail" className="p-0">
                                        <UListItem
                                            onClick={() => setRedaShow(true)}
                                            icon="file-folder"
                                            className="bg-adjust-tone-20 hover:bg-brand-maggioli-19 hover:text-brand-maggioli-06 cursor-pointer transition-colors p-4 rounded"
                                        >
                                            {tree.voce}
                                        </UListItem>
                                    </UList>
                                    <Redazionale modalShow={redaShow} setModalShow={setRedaShow} idTree={tree.id} title={tree.voce} />
                                </Grid>
                            </div>
                        )}

                        {/* Legacy se c'è */}
                        {idLegacy === "1" && isAuthored && user.role !== ILIBRO_ROLE && (
                            <div className="mb-4">
                                <UList iconSize="normal" text="text-secondary text-secondary--detail" className="p-0">
                                    <UListItem
                                        onClick={() => onClickLegacyLink()}
                                        icon="navigate-external-link"
                                        className="bg-adjust-tone-20 hover:bg-brand-maggioli-19 hover:text-brand-maggioli-06 cursor-pointer transition-colors p-4 rounded"
                                    >
                                        Contenuti aggiuntivi
                                    </UListItem>
                                </UList>
                            </div>
                        )}

                        {user.role !== ILIBRO_ROLE && isAuthored && attach.length > 0 ? (
                            <div>
                                <H4 className="mb-4">Consulta i documenti pubblicati</H4>
                                <Grid gutter="small" columns="2">
                                    {/* Ricerca allegato */}
                                    <InputText
                                        label="Cerca"
                                        className="mb-4"
                                        placeholder="Cerca per titolo..."
                                        icon="data-search"
                                        onChange={(e: ChangeEvent<HTMLFormElement>) => filterAttach(e.target.value)}
                                    />

                                    {/* Paginatore allegati */}
                                    {pages > 1 && areas.length === 0 && (
                                        <Row lastChild="to-right" className="">
                                            <Paginator
                                                pages={pages}
                                                currentPage={currentPage}
                                                onClick={onClickPage}
                                                onClickPrev={onClickPrevPage}
                                                onClickNext={onClickNextPage}
                                            />
                                        </Row>
                                    )}

                                    {/* Filtra per categoria */}
                                    {areas.length > 1 && (
                                        <Select
                                            onChange={(e: ChangeEvent<HTMLFormElement>) => selectArea(e.target.value)}
                                            value={selArea}
                                            label="Filtra per categoria"
                                            className="mb-4"
                                            data={[
                                                "Tutte",
                                                ...areas
                                                    .sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
                                                    .map((a) => a.title),
                                            ]}
                                        />
                                    )}
                                </Grid>

                                {/* Paginatore allegati */}
                                {pages > 1 && areas.length > 0 && (
                                    <Row lastChild="to-right" className="">
                                        <Paginator
                                            pages={pages}
                                            currentPage={currentPage}
                                            onClick={onClickPage}
                                            onClickPrev={onClickPrevPage}
                                            onClickNext={onClickNextPage}
                                        />
                                    </Row>
                                )}

                                {filteredAttach.length > 0 ? (
                                    <Grid className="my-5">
                                        {filteredAttach.slice(start, end).map((elem, i: number) => (
                                            <div key={i}>
                                                <DownloadAttach attach={elem} areas={areas} bookID={id} loader={handleLoader} />
                                            </div>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Banner status="warning" className="w-full rounded-2xl text-secondary text-secondary--detail mb-5">
                                        La ricerca non ha prodotto risultati.
                                    </Banner>
                                )}

                                {pages > 1 && (
                                    <Row lastChild="to-right" className="">
                                        <Paginator
                                            pages={pages}
                                            currentPage={currentPage}
                                            onClick={onClickPage}
                                            onClickPrev={onClickPrevPage}
                                            onClickNext={onClickNextPage}
                                        />
                                    </Row>
                                )}
                            </div>
                        ) : (
                            idBancadati === undefined &&
                            idLegacy === undefined &&
                            user.role !== ILIBRO_ROLE &&
                            isAuthored && (
                                <Banner status="warning" className="w-full rounded-2xl text-secondary text-secondary--detail mb-5">
                                    Non sono presenti allegati per questo volume.
                                </Banner>
                            )
                        )}

                        {!isAuthored && (
                            <Banner status="info" className="w-full rounded-2xl text-secondary text-secondary--detail">
                                Non hai riscattato codici per questo volume.
                            </Banner>
                        )}

                        {loggedUser === null && (
                            <Row>
                                <Link to="/login">
                                    <Button variant="primary-outline">Accedi</Button>
                                </Link>
                                <Link to="/users/register">
                                    <Button variant="success">Registrati</Button>
                                </Link>
                            </Row>
                        )}
                    </Grid>
                </Grid>
                <ILibro book={book} show={showIlibro} setShow={setShowIlibro} />
            </div>
        </div>
    );
}

import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import {
    Button,
    H3, 
    Grid,
} from '@maggioli-design-system/react'
import * as TYTexts from '../utils/ThankYouTexts';

export default function ThankYouPage(): ReactElement {

    const type = sessionStorage.getItem('thankYouType');

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <H3 className="text-center vertical-padding-xxsmall">Grazie</H3>
            <Grid className="view-limit text-center" >
                <p dangerouslySetInnerHTML={{ __html: TYTexts.getText(type) }}/>
                <div className="text-center view-limit" >
                    <Link to="/">
                        <Button icon="paginator-first">Torna alla home</Button>
                    </Link>
                </div>
            </Grid>
        </div>
    )
}

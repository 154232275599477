import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Help from "./views/Help";
import NotFound from "./views/NotFound";
import Home from "./views/Home";
import Login from "./views/Login";
import PageTemplate from "./components/PageTemplate";
import MyBooks from "./views/MyBooks";
import BookPage from "./views/BookPage";
import QRcodePage from "./views/QRcodePage";
import Register from "./views/Register";
import GuidaNormativa from "./views/GuidaNormativa";
import Activate from "./views/Activate";
import RecoverPassword from "./views/RecoverPassword";
import { ReactElement } from "react";
import Contact from "./views/Contact";
import CookiePolicy from "./views/CookiePolicy";
import ThankYouPage from "./views/ThankYouPage";
import ResetPassword from "./views/ResetPassword";
import Account from "./views/Account";
import { IHandleLoader } from "./interfaces/IHandleLoader";
import DownloadiLibroApp from "./views/DownloadiLibroApp";
import Authorize from "./views/Authorize";
import { getCookie } from "./utils/Macros";
import { IUserRole } from "./interfaces/IUser";

interface IProps {
    user: IUserRole;
}

export default function App({ user }: IProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);
    const loggedUser: string = getCookie("loggedUser");

    const handleLoader: IHandleLoader = {
        loading: loading,
        setLoading: setLoading,
    };

    return (
        <div className="background-color-adjust-tone">
            <Router>
                <PageTemplate handleLoader={handleLoader} user={user}>
                    <Routes>
                        <Route
                            path="/"
                            element={loggedUser ? <MyBooks handleLoader={handleLoader} user={user} /> : <Home handleLoader={handleLoader} />}
                        />
                        <Route path="/myBooks/page/:page" element={loggedUser ? <MyBooks handleLoader={handleLoader} user={user} /> : <Login />} />
                        <Route path="/book/:id" element={<BookPage handleLoader={handleLoader} user={user} />} />
                        <Route path="/guida-normativa" element={<GuidaNormativa handleLoader={handleLoader} />} />
                        <Route path="/login" element={loggedUser ? <MyBooks handleLoader={handleLoader} user={user} /> : <Login />} />
                        <Route path="/4ct!v4t3/:passCode" element={loggedUser ? <MyBooks handleLoader={handleLoader} user={user} /> : <Activate />} />
                        <Route
                            path="/account"
                            element={loggedUser ? <Account handleLoader={handleLoader} /> : <Register handleLoader={handleLoader} />}
                        />
                        <Route path="/uploads/attachments" element={loggedUser ? <MyBooks handleLoader={handleLoader} user={user} /> : <Login />} />
                        <Route path="/download-ilibro" element={<DownloadiLibroApp handleLoader={handleLoader} />} />
                        <Route
                            path="/users/register"
                            element={loggedUser ? <MyBooks handleLoader={handleLoader} user={user} /> : <Register handleLoader={handleLoader} />}
                        />
                        <Route path="/recover" element={<RecoverPassword handleLoader={handleLoader} />} />
                        <Route path="/pages/help" element={<Help handleLoader={handleLoader} />} />
                        <Route path="/qrcode/:id" element={loggedUser ? <QRcodePage handleLoader={handleLoader} /> : <Login />} />
                        <Route path="/resetPassword/:code" element={<ResetPassword />} />
                        <Route path="/contact" element={<Contact handleLoader={handleLoader} />} />
                        <Route path="/informativa-sui-cookies" element={<CookiePolicy />} />
                        <Route path="/authorize/:token" element={<Authorize />} />
                        <Route path="/thank-you" element={<ThankYouPage />} />
                        <Route element={<NotFound />} />
                    </Routes>
                </PageTemplate>
            </Router>
        </div>
    );
}

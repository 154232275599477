import React from "react";
import ReactDOM from "react-dom";
import "@maggioli-design-system/icons/mgg-icons.css";
import "typeface-karla";
import "typeface-roboto";

import App from "./App";
import Singleton from "./utils/Singleton";
import { getCookie } from "./utils/Macros";
import { checkUserRole } from "./services/UserServices";
import { logout } from "./utils/Functions";

const awaitToken = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_REDA_URL + "api/authenticate", {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            body: JSON.stringify({
                username: process.env.REACT_APP_REDA_USER,
                password: process.env.REACT_APP_REDA_PASS,
            }),
        });

        const content = await response.json();

        return content.id_token;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const Render = () => {
    const loggedUser = getCookie("loggedUser");
    const [render, setRender] = React.useState(false);
    const [isWorker, setIsWorker] = React.useState(false);
    const [role, setRole] = React.useState("");

    const user = {
        isWorker,
        role,
    };

    React.useEffect(() => {
        loader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkUserAccess = async () => {
        if (loggedUser === null) {
            return;
        }
        const user = JSON.parse(getCookie("loggedUser"));
        const res = await checkUserRole(user.id, user.email);
        if (!res.ok) {
            return;
        }
        const data = await res.json();
        if (data.role === "") {
            logout();
            return;
        }
        setIsWorker(data.status);
        setRole(data.role);
    };

    const loader = async () => {
        await checkUserAccess();
        await import("./assets/css/main.css");
        await import("@maggioli-design-system/styles/dist/css/styles.css");
        await import("@maggioli-design-system/design-tokens/dist/css/vars-rgb-channels.css");
        await import("./assets/css/app.css");
        const token = await awaitToken();
        if (!token) {
            setRender(false);
        } else {
            Singleton.getInstance(token);
            setRender(true);
        }
    };

    return render ? <App user={user} /> : null;
};

ReactDOM.render(
    <React.StrictMode>
        <Render />
    </React.StrictMode>,
    document.querySelector("#root"),
);
